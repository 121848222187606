<template>
  <div v-if="Object.keys(columns).length" class="list__item -heading">
    <div v-if="checkable" class="list__column list__checkable">
      <div class="checkbox-primary custom-control custom-checkbox">
        <input
          id="item-all"
          v-model="listCheckAll"
          :disabled="Object.keys(this.datas).length === 0"
          class="custom-control-input"
          name="listCheckAll"
          type="checkbox"
          value="1"
          @click="checkAll($event)"
        />
        <label class="custom-control-label" for="item-all"></label>
      </div>
    </div>
    <div
      v-for="column in columns"
      :key="column"
      :class="
        (column.sortable ? 'list__sortable ' : '') +
        'list__column -heading textStyle w-' +
        nbColumns
      "
      @click="sort($event, column)"
    >
      {{
        column.isTranslatedName
          ? column.name
          : $t((column.tradPrefix ? column.tradPrefix + "." : "") + column.name)
      }}
      <FontAwesomeIcon
        v-if="column.sortable"
        :icon="
          'fa-light ' +
          (column.sortWay ? 'fa-sort-' + column.sortWay : 'fa-sort')
        "
      />
    </div>
    <div v-if="Object.keys(actions).length" class="list__action"></div>
  </div>
</template>

<script>
export default {
  name: "SortTableFirstRowComponent",
  props: {
    datas: {
      default: [],
      type: [],
      required: true,
    },
    columns: {
      default: [],
      type: [],
      required: true,
    },
    checkable: {
      default: false,
      type: Boolean,
      required: false,
    },
    nbColumns: {
      type: Number,
      required: true,
    },
    actions: {
      default: [],
      type: [],
      required: false,
    },
  },
  data() {
    return {
      listCheckAll: 0,
    };
  },
  methods: {
    checkAll(e) {
      this.$emit("checkAll", e, this.listCheckAll);
    },
    sort(e, column) {
      this.$emit("sort", e, column);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  min-height: 350px;
  position: relative;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 8px;
    position: relative;

    @include up-to-lg {
      flex-direction: column;
    }

    @include dark-theme {
      color: $white;
    }

    &.-heading {
      font-weight: 700;
      font-size: 14px;

      @include up-to-lg {
        display: none;
      }

      .list__sortable {
        cursor: pointer;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__column {
    padding-right: 15px;
    padding-top: 8px;

    &.-heading {
      text-transform: capitalize;
    }

    &.text-overflow-hidden {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.w-8 {
      width: calc(83% / 8);
    }

    &.w-7 {
      width: calc(83% / 7);
    }

    &.w-6 {
      width: calc(83% / 6);
    }

    &.w-5 {
      width: calc(83% / 5);
    }

    &.w-4 {
      width: calc(83% / 4);
    }

    &.w-3 {
      width: calc(83% / 3);
    }

    &.w-2 {
      width: calc(83% / 2);
    }

    &.w-1 {
      width: 83%;
    }

    @include up-to-lg {
      &.w-1,
      &.w-2,
      &.w-3,
      &.w-4,
      &.w-5,
      &.w-6,
      &.w-7 {
        width: 100%;
      }

      &:nth-child(1) {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .btn-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      &:hover,
      &:focus {
        border: 1px solid $blue;
      }
    }
  }

  &__action {
    width: 15%;

    &.-row {
      display: flex;
      justify-content: flex-end;
    }

    @include up-to-lg {
      width: 100%;
      margin: 8px 0;
    }
  }
}
</style>
