import { apiConnection } from "@/services/api-connection";
import store from "@/store";
import router from "@/router";

const URL_INFOS = "/app/user/me";
const URL_ONE_USER = "/app/user/info/";
const URL_USER = "/ufauser/";
const URL_CHECK = "check_username";
const URL_POST = "/app/user/put/";
const URL_POST_PICTURE = "/post-picture";

class UserManager {
  loadUserInfos() {
    return apiConnection.get(URL_INFOS, {}, true).then((response) => response);
  }

  loadUser(id) {
    return apiConnection.get(URL_ONE_USER + id).then((response) => response);
  }

  checkUsername = (username) => {
    return apiConnection
      .get(
        URL_USER + URL_CHECK,
        {
          username: username,
        },
        false,
      )
      .then((response) => response);
  };

  logout() {
    window.localStorage.removeItem("studea-refreshToken");
    window.sessionStorage.removeItem("studea-refreshToken");
    window.localStorage.removeItem("studea-returnPathName");
    window.localStorage.removeItem("studea-pageTitle");
    window.localStorage.removeItem("studea-token");
    window.sessionStorage.removeItem("studea-token");
    window.sessionStorage.removeItem("studea-switched");
    window.sessionStorage.removeItem("studea-switchedPopup");
    window.localStorage.removeItem("studea-stayConnected");
    window.localStorage.removeItem("studea-oldTriad");
    window.localStorage.removeItem("studea-oldTrainingCourse");
    store.commit("SET_APP_LOADING", true);
    store.dispatch("initStore");
    // window.location.reload();
    setTimeout(function () {
      router.push({ name: "Login" });
      store.commit("SET_APP_LOADING", false);
    }, 300);
  }

  putUserInfos(id, datas) {
    return apiConnection.put(URL_POST + id, datas).then((response) => response);
  }

  postUserPicture(data, folder) {
    return apiConnection
      .post(
        URL_POST_PICTURE,
        {
          data: data.data,
          filename: data.fileName,
          type: data.type,
          folder: folder,
        },
        true,
      )
      .then((response) => response);
  }

  getProfileKey(profile) {
    let key;
    switch (profile) {
      case 1:
        key = "apprentice";
        break;
      case 2:
        key = "tutor";
        break;
      case 3:
        key = "apprenticeMaster";
        break;
      case 4:
        key = "studeaManager";
        break;
    }
    return key;
  }

  getProfileByName(profileName) {
    let key;
    switch (profileName) {
      case "apprentices":
        key = 1;
        break;
      case "tutors":
        key = 2;
        break;
      case "apprenticeMasters":
        key = 3;
        break;
      case "studeaManagers":
        key = 4;
        break;
    }
    return key;
  }

  checkUserProfile(user, triad) {
    if (triad.apprentice && user.id === triad.apprentice.id) {
      return 1;
    } else if (triad.tutor && user.id === triad.tutor.id) {
      return 2;
    } else if (
      triad.apprenticeMaster &&
      user.id === triad.apprenticeMaster.id
    ) {
      return 3;
    } else {
      return 4;
    }
  }

  findUserType(userType, triad) {
    if (triad.triadUsers) {
      return triad.triadUsers.filter(
        (triadUser) => triadUser.userType === userType,
      );
    }
  }
}

export const userManager = new UserManager();
