<template>
  <div class="list__heading">
    <div class="list__results">
      <div class="form-group mt-2 mt-md-none">
        <span>{{ $t("display") }}</span>
        <select
          v-model="limit"
          :disabled="Object.keys(this.datas).length === 0"
          class="form-control textStyle"
          @change="limitList"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <span>{{ $t("elements") }}</span>
      </div>
      <div class="form-group mobile d-flex d-lg-none">
        <span>{{ $t("sortBy") }}</span>
        <select
          v-model="sortColumn"
          :disabled="Object.keys(this.datas).length === 0"
          class="form-control textStyle"
          @change="changeSortColumn"
        >
          <option value="0"></option>
          <option
            v-for="column in columns"
            v-show="column.sortable"
            :key="column"
            :value="column.label"
          >
            {{
              column.isTranslatedName
                ? column.label
                : $t(
                    (column.tradPrefix ? column.tradPrefix + "." : "") +
                      column.name,
                  )
            }}
          </option>
        </select>
      </div>
      <div
        v-if="sortColumn && sortColumn !== '0'"
        class="form-group mobile d-flex d-lg-none"
      >
        <span>{{ $t("order") }}</span>
        <select
          v-model="sortType"
          :disabled="Object.keys(this.datas).length === 0"
          class="form-control textStyle"
          @change="changeSortType"
        >
          <option value="asc">{{ $t("asc") }}</option>
          <option value="desc">{{ $t("desc") }}</option>
        </select>
      </div>
      <div
        class="d-lg-none d-flex justify-content-end pe-4 mb-2"
        v-if="Object.keys(datas).length && checkable"
      >
        <div class="checkbox-primary custom-control custom-checkbox p-0">
          <input
            id="item-all-mobile"
            v-model="listCheckAll"
            :disabled="Object.keys(this.datas).length === 0"
            class="custom-control-input"
            name="listCheckAll"
            type="checkbox"
            value="1"
            @click="checkAll($event)"
          />
          <label
            class="custom-control-label -checkMarkRight textStyle"
            for="item-all-mobile"
            >{{ $t("All") }} / {{ $t("None") }}</label
          >
        </div>
      </div>
    </div>
    <div class="list__search">
      <InputTypeComponent :model="keywordSearch" @input="search" />
    </div>
  </div>
</template>

<script>
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";

export default {
  name: "SortTableFiltersComponent",
  components: { InputTypeComponent },
  props: {
    datas: {
      default: [],
      type: [],
      required: true,
    },
    columns: {
      default: [],
      type: [],
      required: true,
    },
    checkable: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      listCheckAll: 0,
      limit: 10,
      sortColumn: "",
      sortType: "ASC",
      keywordSearch: {
        vars: {
          name: "keyword",
          value: "",
          id: "keyword",
          required: false,
          block_prefixes: ["filter", "text"],
          disabled: false,
          attr: {
            placeholder: this.$t("filter"),
          },
          row_attr: {
            class: "h-100",
          },
        },
      },
    };
  },
  methods: {
    search() {
      this.$emit("search", this.keywordSearch);
    },
    limitList() {
      this.$emit("limitList", this.limit);
    },
    changeSortColumn() {
      this.$emit("changeSortColumns", this.sortColumn);
    },
    changeSortType() {
      this.$emit("changeSortType", this.sortType);
    },
    checkAll(e) {
      this.$emit("checkAll", e, this.listCheckAll);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include up-to-lg {
      flex-direction: column;
      border-bottom: 3px solid $light-grey;
    }

    .form-control {
      margin: 0 5px;
      height: 35px;
      width: 50px;
      padding: 0 10px;
      font-weight: 700;

      @include dark-theme {
        color: $white;
      }
    }
  }

  &__search {
    height: 35px;
    order: 2;

    @include up-to-lg {
      margin-bottom: 15px;
      order: 1;
      width: 100%;
    }
  }

  &__noresult {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $light-grey;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    font-size: 16px;
    max-width: 375px;
    width: 100%;

    @include up-to-md {
      position: relative;
      transform: translateX(-50%);
      top: initial;
      font-size: 14px;
      text-align: center;
      width: 100%;
      padding: 15px;
    }
  }

  &__checkable {
    width: 30px;
    flex: initial;
    padding: 0;
    position: relative;
    top: 5px;

    .custom-control-label {
      position: initial;

      &:before,
      &:after {
        left: 0;
      }
    }

    @include up-to-lg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__results {
    order: 1;

    @include up-to-lg {
      width: 100%;
      order: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include up-to-md {
      flex-direction: column;
    }

    @include dark-theme {
      color: $white;
    }

    .form-group {
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-right: 15px;

      &.mobile {
        width: 200px;
        justify-content: space-between;

        .form-control {
          width: 120px;
        }
      }
    }
  }
}
</style>
