<template>
  <div
    v-if="
      answer &&
      answer.arrayValue &&
      answer.arrayValue.answers &&
      answer.arrayValue.answers.length
    "
    :class="'answer card --' + answer.writerProfile"
  >
    <div class="answer__heading">
      <div class="answer__identity">
        {{ answer.writerName }} {{ $t("answered") }}
        {{ answer.date ? formatDate(answer.date, "short_time") : "" }}
      </div>
      <div class="answer__type">
        {{ $t(profileLabel(answer.writerProfile)) }}
      </div>
    </div>
    <div class="answer__content">
      <div v-if="item.type === 2">
        {{ answer.arrayValue.answers ? answer.arrayValue.answers.value : "" }}
      </div>
      <div v-else-if="item.type === 11">
        {{
          answer.arrayValue.answers ? formatDate(answer.arrayValue.answers) : ""
        }}
      </div>
      <div v-else-if="item.type === 12">
        {{
          answer.arrayValue.answers
            ? formatDate(answer.arrayValue.answers, "short_time")
            : ""
        }}
      </div>
      <div v-else-if="item.type === 10">
        <a
          :class="isPicture() ? 'answer__image' : ''"
          :href="fileDownloadLink()"
        >
          <span v-if="!isPicture()">{{
            answer.arrayValue.answers.originalName
          }}</span>
          <img v-else :src="fileSrc()" />
        </a>
      </div>
      <div v-else v-html="answer.arrayValue.answers"></div>
      <QuestionnaireAddCommentComponent
        :answer="answer"
        :commenting="commenting"
        :item="item"
        :onQuestion="false"
        @draft="draft"
        @toggleComment="toggleComment"
        @commentAdded="commentAdded"
        @isLoading="isLoading"
      />
    </div>
  </div>
  <QuestionnaireCommentComponent
    v-if="answer.answerComments"
    :comments="answer.answerComments"
  />
</template>

<script>
import QuestionnaireCommentComponent from "@/components/questionnaire/QuestionnaireCommentComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { loginManager } from "@/services/security/login-manager";
import QuestionnaireAddCommentComponent from "@/components/questionnaire/QuestionnaireAddCommentComponent.vue";
import { userManager } from "@/services/security/user-manager";

export default {
  name: "QuestionnaireAnswerComponent",
  components: {
    QuestionnaireAddCommentComponent,
    QuestionnaireCommentComponent,
  },
  props: {
    answer: {
      type: [],
      required: true,
      default: [],
    },
    item: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {
      commenting: false,
      commentModel: null,
    };
  },
  methods: {
    formatDate(date, type = "short") {
      return dateFormatter.format(date, type);
    },

    isPicture() {
      if (this.answer && this.answer.value) {
        return new RegExp("([./])(gif|jpe?g|png)$").test(
          this.answer.value.pathName,
        );
      }
      return false;
    },

    profileLabel(key) {
      const name = userManager.getProfileKey(parseInt(key));
      return name.charAt(0).toUpperCase() + name.slice(1);
    },

    fileDownloadLink() {
      const platform = loginManager.getApiPath().slice(0, -3);
      if (this.answer && this.answer.value) {
        return (
          platform +
          "download-file/path-name-" +
          this.answer.value.pathName +
          "/original-name-" +
          this.answer.value.originalName
        );
      }
      return "";
    },

    fileSrc() {
      const platform = loginManager.getApiPath().slice(0, -3);
      if (this.answer && this.answer.value) {
        return (
          platform +
          "inline-file/path-name-" +
          this.answer.value.pathName +
          "/original-name-" +
          this.answer.value.originalName
        );
      }
      return "";
    },

    toggleComment() {
      this.commenting = !this.commenting;
    },

    commentAdded(payload) {
      this.$emit("commentAdded", payload);
    },

    isLoading(loading) {
      this.$emit("isLoading", loading);
    },

    draft(model) {
      this.$emit("draft", model);
    },
  },
};
</script>

<style lang="scss" scoped>
.answer {
  padding: 10px;
  border: 2px solid var(--profile-color);

  &__content {
    @include dark-theme {
      color: $white;
    }
  }

  &__image {
    max-height: 150px;
    text-align: center;
    display: block;

    img {
      max-height: 150px;
      width: auto;
      object-fit: contain;
      display: inline-block;
    }
  }

  &.--1 {
    --profile-color: #6ac7a4;
  }

  &.--2 {
    --profile-color: #546bd1;
  }

  &.--3 {
    --profile-color: #da5164;
  }

  &.--4 {
    --profile-color: #f8a007;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: 5px;
    color: var(--profile-color);
  }
}
</style>
