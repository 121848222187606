import { apiConnection } from "@/services/api-connection";
import store from "@/store";
import { fileManager } from "@/services/file/file-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { formValidator } from "@/services/form/form-validator";
import router from "@/router";
import { formManager } from "@/services/form/form-manager";

const URL_QUESTIONNAIRES = "/app/questionnaire/list/";
const URL_QUESTIONNAIRE = "/app/questionnaire/";
const URL_POST = "/app/questionnaire/save/";
const URL_PDF = "/app/questionnaire/export/";

class QuestionnaireManager {
  postAdminQuestionnaire(data, trainingCourseId, id = null) {
    const url = "/app/studea-manager/" + trainingCourseId + "/questionnaire";
    return (
      id
        ? apiConnection.put(url + "/" + id, data)
        : apiConnection.post(url, data)
    ).then((response) => response);
  }

  getAdminQuestionnaireList(trainingCourseId) {
    const url = "/app/studea-manager/" + trainingCourseId + "/questionnaires";
    return apiConnection.get(url, {}).then((response) => response);
  }

  getAdminQuestionnaire(id, trainingCourseId) {
    const url =
      "/app/studea-manager/" + trainingCourseId + "/questionnaire/" + id;
    return apiConnection.get(url, {}).then((response) => response);
  }

  loadQuestionnaires = (id) => {
    return apiConnection
      .get(URL_QUESTIONNAIRES + id, {}, true)
      .then((response) => response);
  };

  loadQuestionnaire = (questionnaireId, triadId) => {
    return apiConnection
      .get(URL_QUESTIONNAIRE + triadId + "/" + questionnaireId, {}, true)
      .then((response) => response);
  };

  getPdf = (triadId, questionnaireId, model = 0) => {
    store.commit("FILE_DOWNLOAD_REQUEST");
    return apiConnection
      .get(URL_PDF + triadId + "/" + questionnaireId, { model: model })
      .then((success) => {
        if (success.data) {
          fileManager.saveFile(
            success.filename,
            success.data,
            success.filetype,
          );
        } else {
          notificationManager.showAlert("error", i18n.global.t("error_file"));
        }
        store.commit("FILE_DOWNLOAD_SUCCESS");
      });
  };

  postQuestionnaire = (questionnaireId, triadId, draft, model, profile) => {
    if (!model) {
      model = [];
    }
    return apiConnection
      .put(
        URL_POST + triadId + "/" + questionnaireId,
        {
          draft: draft,
          model: model,
          profile: profile,
        },
        true,
      )
      .then((response) => response);
  };

  getFieldBlockPrefixes(type) {
    let block_prefixes = [];
    switch (type) {
      case 0:
        block_prefixes = ["", "text"];
        break;
      case 1:
        block_prefixes = ["", "text", "textarea", "zama_tiny_mce_type"];
        break;
      case 2:
      case 3:
      case 4:
        block_prefixes = ["", "choice"];
        break;
      case 8:
        block_prefixes = ["", "number"];
        break;
      case 10:
        block_prefixes = ["", "file"];
        break;
      case 11:
        block_prefixes = ["", "date"];
        break;
      case 12:
        block_prefixes = ["", "date", "datetime"];
        break;
    }

    return block_prefixes;
  }

  buildModel() {
    const questionnaire = store.state.questionnaire.questionnaire;
    let model = {};
    if (
      questionnaire &&
      questionnaire.questions &&
      Object.keys(questionnaire.questions).length
    ) {
      Object.values(questionnaire.questions).forEach((question) => {
        if (question.itemType === "field") {
          model[question.id] = this.buildField(question);
        } else if (
          question.itemType === "cluster" &&
          question.displayMode === 2
        ) {
          if (question.questions && Object.keys(question.questions).length) {
            Object.values(question.questions).forEach((row) => {
              row["cluster"] = question.id;
              const name =
                question.userAnswer &&
                question.userAnswer.arrayValue &&
                question.userAnswer.arrayValue.name
                  ? question.userAnswer.arrayValue.name
                  : null;
              const comments =
                row.userAnswer &&
                row.userAnswer.arrayValue &&
                row.userAnswer.arrayValue.comments
                  ? row.userAnswer.arrayValue.comments
                  : null;
              let values =
                row.userAnswer &&
                row.userAnswer.arrayValue &&
                row.userAnswer.arrayValue.answers
                  ? row.userAnswer.arrayValue.answers
                  : null;

              if (name) {
                row["name"] = name;
              }
              model[row.id] = this.buildField(row, question.type);
              model[row.id].vars["row"] = true;

              if (comments) {
                model[row.id].vars["comments"] = comments;
              }

              if (!values) {
                values = [];
                for (let i = 0; i < question.valueList.length; i++) {
                  values[i] = "";
                }
              }
              model[row.id].vars.value = values;
            });
          }
          if (question.openedQuestions) {
            if (
              question.userAnswerCollections &&
              Object.keys(question.userAnswerCollections).length
            ) {
              Object.values(question.userAnswerCollections).forEach(
                (userAnswerCollection) => {
                  if (Object.keys(userAnswerCollection.answersValues).length) {
                    Object.values(userAnswerCollection.answersValues).forEach(
                      (row) => {
                        this.addClusterLine(row, question);
                        model[row.id] = this.buildField(
                          row,
                          question.type,
                          question,
                        );
                        model[row.id]["cluster"] = question.id;
                        if (row.arrayValue) {
                          model[row.id]["name"] = row.arrayValue.name
                            ? row.arrayValue.name
                            : null;
                          if (row.arrayValue.comments) {
                            model[row.id].vars["comments"] =
                              row.arrayValue.comments;
                          }
                          row["answer"] = row.arrayValue.answers;
                          model[row.id].vars.value = row.answer;
                        }
                      },
                    );
                  }
                },
              );
            }
          }
        }
      });
    }

    return model;
  }

  buildField(question, type = null, cluster = null, value = null) {
    if (
      question.userAnswer &&
      question.userAnswer.arrayValue &&
      question.userAnswer.arrayValue.answers &&
      !value
    ) {
      value = question.userAnswer.arrayValue.answers;
    }

    const disabled = this.disabled(question);

    const field = {
      vars: {
        value: value,
        block_prefixes: this.getFieldBlockPrefixes(type ? type : question.type),
        required: question.required,
        id: question.id,
        name: "name-" + question.id,
        label: question.title,
        help: this.help(question),
        disabled: disabled,
        ignoreValidation: disabled,
        hide: !question.enabled,
        attr: {},
      },
    };

    if (question.type === 10) {
      field.vars["upload_path"] = "questionnaire";
    }
    if (this.isChoice(type ? type : question.type)) {
      switch (type ? type : question.type) {
        case 2:
          field.vars["multiple"] = false;
          field.vars["expanded"] = false;
          break;
        case 3:
          field.vars["multiple"] = false;
          field.vars["expanded"] = true;
          break;
        case 4:
          field.vars["multiple"] = true;
          field.vars["expanded"] = true;
          break;
      }

      let choices = [];
      (cluster ? cluster : question).valueList.forEach((choice) => {
        choices.push({
          label: choice.value,
          value: choice.value,
          data: choice.value,
          mark: choice.mark ? choice.mark : null,
          checked:
            value &&
            !!Object.values(value).find((item) => item === choice.value),
        });
      });
      field.vars.choices = choices;
      if (this.isCheckbox(field)) {
        field.vars.value = choices;
      }
    }

    return field;
  }

  addClusterLine(answer, cluster) {
    const exist = Object.values(cluster.questions).find(
      (item) => item.id === answer.id,
    );

    if (!exist) {
      store.commit("INIT_LAST_INDEX", this.lastIndex++);

      const item = {
        id: answer.id,
        index: store.state.questionnaireEditor.lastIndex,
        title:
          answer.arrayValue && answer.arrayValue.name
            ? answer.arrayValue.name
            : "",
        type: cluster.type,
        clusterType: "field",
        access: cluster.openedQuestionsAccess,
        comments: cluster.comments,
        commentsLabel: cluster.commentsLabel,
        commentsType: cluster.commentsType,
        openedQuestionsAccess: [],
        openedQuestions: false,
        openedQuestionsType: 1,
        enabled: this.isEnabled(cluster),
      };

      cluster.questions.push(item);
    }
  }

  isEnabled(question) {
    const questionnaire = store.state.questionnaire.questionnaire;
    return (
      question.openedQuestionsAccess.includes(questionnaire.userProfile) &&
      questionnaire.userHasToComplete &&
      !questionnaire.hasSigned
    );
  }

  isChoice(type) {
    return [2, 3, 4].includes(type);
  }

  help(question) {
    return question.tipActive && question.tip ? question.tip : null;
  }

  disabled(question) {
    let available =
      store.state.questionnaire.questionnaire.userHasToComplete &&
      !store.state.questionnaire.questionnaire.userHasSigned &&
      question.enabled;

    if (store.state.user.user.roles.includes("ROLE_STUDEA_ADMIN")) {
      available = false;
    }

    return !available;
  }

  isCheckbox(question) {
    return formManager.isCheckboxType(question);
  }

  saveQuestionnaire(replyStatus, message, error) {
    let validation = [];
    if (replyStatus === 3) {
      validation = formValidator.validForm(
        store.state.questionnaire.model,
        true,
      );
    }
    return new Promise((resolve, reject) => {
      if (
        (validation.errors && !validation.errors.length) ||
        replyStatus !== 3
      ) {
        const modelToSave = {};

        Object.values(store.state.questionnaire.model).forEach((question) => {
          modelToSave[question.vars.id] = {};
          let value;
          if (this.isCheckbox(question)) {
            value = [];
            Object.values(question.vars.value).forEach((choice) => {
              if (typeof choice !== "string" && choice.checked) {
                value.push(choice.value);
              } else if (typeof choice === "string") {
                value.push(choice);
              }
            });
          } else {
            value = question.vars.value;
          }

          modelToSave[question.vars.id]["answers"] = value;

          if (question.vars.answerComment) {
            modelToSave[question.vars.id]["answerComment"] =
              question.vars.answerComment;
          }
          if (question.vars.answerId) {
            modelToSave[question.vars.id]["answerId"] = question.vars.answerId;
          }
          if (question.name) {
            modelToSave[question.vars.id]["name"] = question.name;
          }
          if (question.vars.comments) {
            modelToSave[question.vars.id]["comments"] = question.vars.comments;
          }
          if (question.cluster) {
            modelToSave[question.vars.id]["cluster"] = question.cluster;
          }
          if (question.delete) {
            modelToSave[question.vars.id]["delete"] = true;
          }
        });
        this.postQuestionnaire(
          store.state.questionnaire.questionnaire.id,
          store.state.triad.triad.id,
          replyStatus,
          modelToSave,
          store.state.triad.triad.userRoles[0],
        ).then((success) => {
          if (!success.error) {
            Object.keys(modelToSave).forEach((id) => {
              if (id.includes("new-")) {
                delete modelToSave[id];
              }
            });
            if (replyStatus !== 1) {
              notificationManager.showAlert("success", message);
              store.dispatch("fetchQuestionnaires", store.state.triad.triad.id);
              router.push({ name: "Dashboard" });
            } else {
              notificationManager.showNotification("success", message);
            }
            resolve(success);
          } else {
            notificationManager.showAlert("error", error);
            reject(error);
          }
        });
      } else {
        resolve(validation.errors);
      }
    });
  }

  addRow(triadId, questionnaireId, clusterId) {
    return apiConnection
      .post(
        "/app/questionnaire/add-row/" +
          triadId +
          "/" +
          questionnaireId +
          "/" +
          clusterId,
      )
      .then((answer) => answer);
  }

  addComment(triadId, questionnaireId, answerId, comment) {
    return apiConnection
      .post(
        "/app/questionnaire/add-comment/" +
          triadId +
          "/" +
          questionnaireId +
          "/" +
          answerId,
        { comment: comment },
      )
      .then((comment) => comment);
  }

  processQuestionnaire(questionnaire) {
    const choicesToProcess = [
      "signedBy",
      "visibleBy",
      "marksVisibleBy",
      "canBeCopiedBy",
      "canBeCopiedBy",
      "triads",
      "crews",
      "secondaryQuestionnaires",
    ];
    const fieldsToProcess = [
      "defaultApprenticeAccessStartDate",
      "defaultApprenticeAccessEndDate",
      "defaultTutorAccessStartDate",
      "defaultTutorAccessEndDate",
      "defaultApprenticeMasterAccessStartDate",
      "defaultApprenticeMasterAccessEndDate",
      "defaultStudeaManagerAccessStartDate",
      "defaultStudeaManagerAccessEndDate",
      "completenessDate",
      "firstReminder",
      "secondReminder",
    ];

    choicesToProcess.forEach((field) => {
      const values = [];
      if (questionnaire[field] && Object.keys(questionnaire[field]).length) {
        Object.values(questionnaire[field]).forEach((choice) => {
          if (choice.checked) {
            let value = parseInt(choice.value);
            if (["triads", "crews"].includes(field)) {
              value = { id: parseInt(choice.value) };
            }
            values.push(value);
          }
          if (["secondaryQuestionnaires"].includes(field)) {
            values.push({ id: parseInt(choice.value) });
          }
        });
      }
      questionnaire[field] = values;
    });

    fieldsToProcess.forEach((field) => {
      if (!questionnaire[field]) {
        questionnaire[field] = null;
      }
    });

    questionnaire["interviewDateVisibleBy"] = questionnaire[
      "interviewDateVisibleBy"
    ]
      ? parseInt(questionnaire["interviewDateVisibleBy"])
      : 0;

    if (
      questionnaire.questions &&
      Object.keys(questionnaire.questions).length
    ) {
      Object.values(questionnaire.questions).forEach((question) => {
        delete question["selected"];
        delete question["index"];

        if (question.questions && Object.keys(question.questions).length) {
          Object.values(question.questions).forEach((subQuestion) => {
            delete subQuestion["selected"];
            delete subQuestion["index"];
          });
        }
      });
    }

    return questionnaire;
  }

  updateFields(models, checkDates = false) {
    models = Object.values(models);
    const visibleBy = models.find((item) => item.vars.name === "visibleBy");

    const visibleByApprentice = visibleBy
      ? visibleBy.vars.value.find((item) => item.data === 1)
      : null;
    const visibleByTutor = visibleBy
      ? visibleBy.vars.value.find((item) => item.data === 2)
      : null;
    const visibleByApprenticeMaster = visibleBy
      ? visibleBy.vars.value.find((item) => item.data === 3)
      : null;

    const signedBy = models.find((item) => item.vars.name === "signedBy");
    const signedByApprentice = signedBy
      ? signedBy.vars.value.find((item) => item.data === 1)
      : null;
    const apprenticeModelStartDate = models.find(
      (item) => item.vars.name === "defaultApprenticeAccessStartDate",
    );
    const apprenticeModelEndDate = models.find(
      (item) => item.vars.name === "defaultApprenticeAccessEndDate",
    );

    const signedByTutor = signedBy
      ? signedBy.vars.value.find((item) => item.data === 2)
      : null;
    const tutorModelStartDate = models.find(
      (item) => item.vars.name === "defaultTutorAccessStartDate",
    );
    const tutorModelEndDate = models.find(
      (item) => item.vars.name === "defaultTutorAccessEndDate",
    );

    const signedByApprenticeMaster = signedBy
      ? signedBy.vars.value.find((item) => item.data === 3)
      : null;
    const apprenticeMasterModelStartDate = models.find(
      (item) => item.vars.name === "defaultApprenticeMasterAccessStartDate",
    );
    const apprenticeMasterModelEndDate = models.find(
      (item) => item.vars.name === "defaultApprenticeMasterAccessEndDate",
    );

    const signedByLeaManager = signedBy
      ? signedBy.vars.value.find((item) => item.data === 4)
      : null;
    const leaManagerModelStartDate = models.find(
      (item) => item.vars.name === "defaultStudeaManagerAccessStartDate",
    );
    const leaManagerModelEndDate = models.find(
      (item) => item.vars.name === "defaultStudeaManagerAccessEndDate",
    );

    if (checkDates) {
      const defaultDate = models.find(
        (item) => item.vars.name === "defaultAllAccessDate",
      );

      if (defaultDate) {
        const startDates = [
          "defaultApprenticeAccessStartDate",
          "defaultTutorAccessStartDate",
          "defaultApprenticeMasterAccessStartDate",
          "defaultStudeaManagerAccessStartDate",
        ];

        const endDates = [
          "defaultApprenticeAccessEndDate",
          "defaultTutorAccessEndDate",
          "defaultApprenticeMasterAccessEndDate",
          "defaultStudeaManagerAccessEndDate",
        ];

        const defaultStartDate = models.find(
          (item) => item.vars.name === "defaultAllAccessStartDate",
        );
        const defaultEndDate = models.find(
          (item) => item.vars.name === "defaultAllAccessEndDate",
        );

        startDates.forEach((date) => {
          const field = models.find((item) => item.vars.name === date);
          field.vars.value = defaultStartDate.vars.value;
        });

        endDates.forEach((date) => {
          const field = models.find((item) => item.vars.name === date);
          field.vars.value = defaultEndDate.vars.value;
        });
      }
    }

    if (signedByApprentice) {
      signedByApprentice.disabled = !visibleByApprentice.checked;
      signedByApprentice.checked = signedByApprentice.disabled
        ? false
        : signedByApprentice.checked;
    }

    if (signedByTutor) {
      signedByTutor.disabled = !visibleByTutor.checked;
      signedByTutor.checked = signedByTutor.disabled
        ? false
        : signedByTutor.checked;
    }

    if (signedByApprenticeMaster) {
      signedByApprenticeMaster.disabled = !visibleByApprenticeMaster.checked;
      signedByApprenticeMaster.checked = signedByApprenticeMaster.disabled
        ? false
        : signedByApprenticeMaster.checked;
    }

    if (apprenticeModelStartDate && signedByApprentice) {
      apprenticeModelStartDate.vars.required = signedByApprentice.checked;
    }

    if (apprenticeModelEndDate && signedByApprentice) {
      apprenticeModelEndDate.vars.required = signedByApprentice.checked;
    }

    if (tutorModelStartDate && signedByTutor) {
      tutorModelStartDate.vars.required = signedByTutor.checked;
    }

    if (tutorModelEndDate && signedByTutor) {
      tutorModelEndDate.vars.required = signedByTutor.checked;
    }

    if (apprenticeMasterModelStartDate && signedByApprenticeMaster) {
      apprenticeMasterModelStartDate.vars.required =
        signedByApprenticeMaster.checked;
    }

    if (apprenticeMasterModelEndDate && signedByApprenticeMaster) {
      apprenticeMasterModelEndDate.vars.required =
        signedByApprenticeMaster.checked;
    }

    if (leaManagerModelStartDate && signedByLeaManager) {
      leaManagerModelStartDate.vars.required = signedByLeaManager.checked;
      leaManagerModelEndDate.vars.required = signedByLeaManager.checked;
    }
  }

  validQuestion(question, valid) {
    const isCluster =
      question.itemType === "cluster" &&
      question.questions &&
      Object.keys(question.questions).length;
    if (question.itemType === "field") {
      if (question.access.length === 0) {
        question["accessError"] = true;
      } else {
        delete question.accessError;
      }

      if (!question.title) {
        question["titleError"] = true;
      } else {
        delete question.titleError;
      }

      if (valid && (question.accessError || question.titleError)) {
        valid = false;
      }
    } else if (isCluster) {
      Object.values(question.questions).forEach((row) => {
        if (row.access.length === 0) {
          row["accessError"] = true;
          row.selected = question.selected;
        } else {
          delete row.accessError;
        }

        if (!row.title) {
          row["titleError"] = true;
        } else {
          delete row.titleError;
        }

        if (valid && (row.accessError || row.titleError)) {
          valid = false;
        }
      });
    }
    return valid;
  }
}

export const questionnaireManager = new QuestionnaireManager();
