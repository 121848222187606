<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 col-xl-9">
        <TrainingCourseListBlockComponent :adminButton="true" />
      </div>
      <div class="col-lg-5 col-xl-3 order-1 order-lg-0">
        <div class="widget" v-if="isStudeaManager()">
          <div class="widget-heading border-none flex-column">
            <router-link
              :to="{ name: 'studeaManagerSharedDocumentList' }"
              class="btn btn-success btnStyle w-100"
              >{{ $t("studea_manager.document.shared_document_list") }}
            </router-link>
            <router-link
              :to="{ name: 'studeaManagerSharedDocumentAdd' }"
              class="btn btn-success btnStyle w-100 mt-2"
              >{{ $t("studea_manager.document.shared_document_add") }}
            </router-link>
          </div>
        </div>
        <QuestionnaireToDoBlockComponent v-if="!isStudeaManager()" />
        <IndicatorBlock />
      </div>
    </div>
  </div>
</template>

<script>
import TrainingCourseListBlockComponent from "@/components/blocks/TrainingCourseListBlockComponent.vue";
import IndicatorBlock from "@/components/blocks/IndicatorBlock.vue";
import { mapState } from "vuex";
import QuestionnaireToDoBlockComponent from "@/components/questionnaire/QuestionnaireToDoBlockComponent.vue";
import { colorManager } from "@/services/platform/color-manager";

export default {
  name: "TrainingCourseListView",
  components: {
    QuestionnaireToDoBlockComponent,
    IndicatorBlock,
    TrainingCourseListBlockComponent,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      design: (state) => state.platform.design,
    }),
  },
  created() {
    this.getTrainingCourses();
  },
  mounted() {
    colorManager.setPlatformStyle(this.design);
  },
  methods: {
    getTrainingCourses() {
      this.$store.dispatch("fetchTrainingCourseList", {}).then(() => {
        this.$store.commit("SET_APP_LOADING", false);
      });
    },

    isStudeaManager() {
      return (
        (this.trainingCourse && this.trainingCourse.isStudeaManager) ||
        this.user.roles.includes("ROLE_STUDEA_ADMIN")
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
