<template>
  <div class="filter">
    <button class="btn btnStyle btn-success" @click="$emit('filter')">
      <FontAwesomeIcon icon="fa-light fa-filter" />
      Filtrer
    </button>
    <button
      class="btn btnStyle btn-success"
      @click="$emit('reset')"
      v-if="isFiltered"
    >
      <FontAwesomeIcon icon="fa-light fa-xmark" />
      Supprimer les filtres
    </button>
  </div>
</template>

<script>
export default {
  name: "FilterBlock",
  props: {
    isFiltered: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.filter {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  left: 20px;

  button {
    width: 250px;
    margin-bottom: 10px;
  }
  svg {
    margin-right: 8px;
  }
}
</style>
