class PictureManager {
  setPicture(itemPicture, prefix = null) {
    if (itemPicture && itemPicture.pathName) {
      let pathName = itemPicture.pathName;

      if (prefix) {
        let split = itemPicture.pathName.split("/");
        split.pop();
        pathName = split.join("/") + "/" + prefix + "_" + itemPicture.name;
      }

      return (
        this.getApiPath().slice(0, -3) +
        "inline-file/path-name-" +
        pathName +
        "/original-name-" +
        itemPicture.originalName
      );
    }
  }

  getApiPath() {
    return window.localStorage.getItem("studea-apiPath")
      ? window.localStorage.getItem("studea-apiPath")
      : window.sessionStorage.getItem("studea-apiPath");
  }
}

export const pictureManager = new PictureManager();
