import { pictureManager } from "@/services/utilities/picture-manager";

class ColorManager {
  buildStyle(design, trainingCourse) {
    let primaryColor = "#000";
    let secondaryColor = "#000";

    if (design) {
      primaryColor = design.principalColor ? design.principalColor : "#000";
      secondaryColor = design.secondaryColor ? design.secondaryColor : "#000";
    }

    if (trainingCourse && trainingCourse.training) {
      primaryColor = trainingCourse.training.institution.principalColor
        ? trainingCourse.training.institution.principalColor
        : "#000";
      secondaryColor = trainingCourse.training.institution.secondaryColor
        ? trainingCourse.training.institution.secondaryColor
        : "#000";
    }

    const css =
      "" +
      "body {" +
      "--primary-color: " +
      primaryColor +
      " !important;" +
      "--secondary-color: " +
      secondaryColor +
      " !important;" +
      "}";

    let style = document.createElement("style");
    style.type = "text/css";
    style.id = "platformStyle";
    style.innerHTML = css;
    document.getElementsByTagName("head")[0].appendChild(style);
  }

  setFavicon(design) {
    const favicon = document.querySelector("link[rel~='icon']");
    if (favicon) {
      favicon.href =
        design && design.favicon
          ? pictureManager.setPicture(design.favicon, "favicon")
          : "/favicon.png";
    }
  }

  setPlatformStyle(design, trainingCourse = null) {
    let style = document.getElementById("platformStyle");
    if (style) {
      style.remove();
    }
    this.buildStyle(design, trainingCourse);
    this.setFavicon(design);
  }
}

export const colorManager = new ColorManager();
