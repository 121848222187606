<template>
  <div class="row">
    <div class="col-lg-10 order-lg-0 order-1">
      <div class="widget">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            {{
              $tc("global.list.title", 1, {
                name: this.$tc("admin.training.title", 2).toLowerCase(),
              })
            }}
            {{
              isFiltered
                ? "(" +
                  Object.keys(datas).length +
                  " résultat" +
                  (Object.keys(datas).length > 1 ? "s" : "") +
                  " filtré" +
                  (Object.keys(datas).length > 1 ? "s" : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <button
              v-if="isFiltered"
              class="btn-icon me-1"
              v-tooltip="{ title: $t('init_search') }"
              type="button"
              @click="resetSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
            </button>
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('search_motor') }"
              type="button"
              @click="openSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            :actions="actions"
            :apiFilters="true"
            :columns="columns"
            :datas="datas"
            :idKey="5"
            :loading="loading"
            :isSearchAvailable="true"
            path="adminTrainingCourseEdit"
            @remove="remove"
            @duplicateTrainingCourse="duplicateTrainingCourse"
            @duplicateTrainingCourseOnNextYear="
              duplicateTrainingCourseOnNextYear
            "
            @removeTrainingCourse="removeTrainingCourse"
            @refresh="refresh"
            @openSearch="openSearch"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-2 accordion order-lg-1 order-0" id="accordionAction">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingAction">
          <button
            :disabled="windowWidth > 991"
            :class="
              'accordion-button ' + (windowWidth < 991 ? 'collapsed' : '')
            "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseAction"
            aria-expanded="true"
            aria-controls="collapseAction"
          >
            {{ $t("actions") }}
          </button>
        </h2>
        <div
          id="collapseAction"
          :class="
            'accordion-collapse collapse ' + (windowWidth > 991 ? 'show' : '')
          "
          aria-labelledby="headingAction"
          data-bs-parent="#accordionAction"
        >
          <div class="accordion-body">
            <router-link
              :to="{
                name: 'adminTrainingsAdd',
                params: { alias: 'training' },
              }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{
                $tc("global.add.title", 2, {
                  name: this.$tc("admin.training.title", 1).toLowerCase(),
                })
              }}
            </router-link>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              @click="generateTrainingTest"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{ $t("admin.training.ufa_add_test") }}
            </button>
            <router-link
              :to="{
                name: 'adminTrainingsImport',
                params: { alias: 'training' },
              }"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-upload" />
              {{
                $tc("global.import.title", 2, {
                  name: this.$tc("admin.training.title", 2).toLowerCase(),
                })
              }}
            </router-link>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{
                $tc("global.export.title", 2, {
                  name: this.$tc("admin.training.title", 2).toLowerCase(),
                })
              }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{
                $tc("global.export.title", 2, {
                  name: this.$tc("admin.registration.title", 2).toLowerCase(),
                })
              }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              @click="generateTriadTest"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-users" />
              <span>{{ $t("admin.training.genere_triad_test") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="
      $tc('global.search.title', 2, {
        name: this.$tc('admin.training.title', 2).toLowerCase(),
      })
    "
    :storageKey="sessionStorageKey"
    @search="search"
  />
</template>

<script>
import { mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "TrainingsListView",
  components: {
    ModalFormComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      sessionStorageKey: "studea-admin-users",
      maxResult: 0,
      actions: [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminTrainingsEdit",
          cfaStyle: false,
          alias: "training",
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
        },
      ],
      columns: [],
      filterModels: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.usersList,
    }),
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    remove(row) {
      adminManager.remove("training", row.id, 2, false).then(() => {
        this.init();
      });
    },

    refresh(params) {
      this.listParams = params;
      this.init();
    },

    removeTrainingCourse(id) {
      adminManager.remove("training-course", id, 2, false).then(() => {
        this.init();
      });
    },

    duplicateTrainingCourse(id) {
      adminManager.duplicate("training-course", id, 2).then(() => {
        this.init();
      });
    },

    duplicateTrainingCourseOnNextYear(id) {
      adminManager
        .duplicate("training-course", id, 2, { nextYear: 1 })
        .then(() => {
          this.init();
        });
    },

    search(params) {
      this.loading = true;
      this.listParams["search"] = params;
      this.getDatas();
      this.isFiltered = true;
    },

    init() {
      this.loading = true;
      this.setParams();
      this.getDatas(true);
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    getDatas() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }
      adminManager.list("training", false, this.listParams).then((data) => {
        this.columns = sortableTableManager.getEntriesColumns(
          data.columns,
          this.columns,
        );
        this.columns[3].sortable = false;
        this.datas = data.data;
        this.maxResult = data.count;
        this.loading = false;
      });
    },

    getFilters() {
      this.filterModels = [];
      adminManager.form("training", null, {}, false, true).then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.isFiltered = false;
      this.getDatas();
      window.sessionStorage.removeItem(this.sessionStorageKey);
    },
    generateTriadTest() {
      this.loading = true;
      adminManager.generateTriadTest().then(() => {
        notificationManager.showNotification(
          "success",
          this.$t("admin.training.genere_triad_test_success"),
        );
        this.loading = false;
        this.init();
      });
    },
    generateTrainingTest() {
      this.loading = true;
      adminManager.generateTrainingTest().then(() => {
        notificationManager.showNotification(
          "success",
          this.$t("admin.training.genere_training_test_success"),
        );
        this.loading = false;
        this.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
