<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 order-1 order-lg-0">
        <TriadMemberBlock />
        <ContractBlock />
        <DocumentsBlock />
      </div>
      <div class="col-lg-6 order-0 order-lg-1">
        <QuestionnaireToDoBlockComponent
          v-if="toComplete.length || toSign.length"
        />
        <QuestionnaireListBlockComponent
          v-for="year in questionnaires"
          :key="year"
          :accordion="true"
          :year="year"
        />

        <div class="d-flex justify-content-end p-3 gap-3">
          <button
            class="btn btnStyle btn-success"
            @click="downloadFileBooklet()"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-down-to-bracket" />
            {{ $t("dashboard.Download_the_booklet") }}
          </button>
          <button
            class="btn btnStyle btn-success"
            @click="downloadFileKeyDates()"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-down-to-bracket" />
            {{ $t("dashboard.Download_key_dates") }}
          </button>
        </div>
      </div>
      <div class="col-lg-3 order-2">
        <ProgressBarBlock :label="$t('yearProgression')" type="purcent" />
        <TimelineBlock />
      </div>
    </div>
  </div>
  <InfosComponent />
</template>

<script>
import { mapState } from "vuex";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import TimelineBlock from "@/components/blocks/TimelineBlock.vue";
import ContractBlock from "@/components/blocks/ContractBlock.vue";
import InfosComponent from "@/components/InfosComponent.vue";
import ProgressBarBlock from "@/components/blocks/ProgressBarBlock.vue";
import DocumentsBlock from "@/components/blocks/DocumentsBlock.vue";
import QuestionnaireToDoBlockComponent from "@/components/questionnaire/QuestionnaireToDoBlockComponent.vue";
import QuestionnaireListBlockComponent from "@/components/questionnaire/QuestionnaireListBlockComponent.vue";
import { triadManager } from "@/services/triad/triad-manager";

export default {
  name: "DashboardView",
  components: {
    QuestionnaireListBlockComponent,
    QuestionnaireToDoBlockComponent,
    DocumentsBlock,
    ProgressBarBlock,
    ContractBlock,
    TimelineBlock,
    TriadMemberBlock,
    InfosComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      model: (state) => state.formModel,
      questionnaires: (state) => state.questionnaire.questionnaires,
      triad: (state) => state.triad.triad,
      user: (state) => state.user.user,
      toSign: (state) => state.questionnaire.toSign,
      toComplete: (state) => state.questionnaire.toComplete,
    }),
  },
  methods: {
    downloadFileBooklet() {
      triadManager.pdf(this.triad.id);
    },
    downloadFileKeyDates() {
      console.log("Download file key dates");
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: $purple;
}

.svg-inline--fa {
  margin-right: 8px;
}
</style>
