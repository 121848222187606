class SortableTableManager {
  getColumns(data, tradPrefix = null) {
    let columns = [];
    Object.keys(data).forEach((el) => {
      if (el !== "id") {
        columns.push({
          name: el,
          tradPrefix: tradPrefix,
          sortable: true,
          sortWay: "",
          filterActive: true,
        });
      }
    });
    return columns;
  }

  getEntriesColumns(columns, oldColumns = null) {
    let res = [];
    Object.entries(columns).forEach((column) => {
      if (column[1] !== "id") {
        res.push({
          name: column[1],
          sortable: true,
          sortWay:
            oldColumns && oldColumns[column[0]] && oldColumns[column[0]].sortWay
              ? oldColumns[column[0]].sortWay
              : "",
          isTranslatedName: true,
          index: column[0],
          filterActive: true,
        });
      }
    });
    return res;
  }
}

export const sortableTableManager = new SortableTableManager();
