export default {
  studea_manager: {
    delete_session_confirmation:
      "Etes-vous sur de vouloir supprimer cette session ?",
    navigation: {
      general: "Général",
      modules: "Modules",
      pilotage: "Pilotage",
    },
    triadUser: {
      title: "utilisateur secondaire",
    },
    home: {
      title: ({ plural }) =>
        plural(["Configuration de la ", "Configuration du "]),
      page_title: "Configuration",
    },
    questionnaire: {
      title: ({ plural }) => plural(["Questionnaire", "Questionnaires"]),
      list: {
        title: "Configuration des questionnaires",
      },
      questionnaire_already_signed:
        "Attention le questionnaire a déjà été signé !",
      start_date: ({ named }) => `Date d'accès ${named("profile")} - du`,
      end_date: "au",

      finish: {
        title: "Terminer ma configuration",
      },
      empty_title:
        "Pour commencer, veuillez ajouter un titre, une question ou une grille de réponse !",
      add_question: "Ajouter une question",
      add_title: "Ajouter un titre",
      add_question_cluster: "Ajouter une grille de questions",
      required: "Obligatoire",
      question_settings: "Paramètre de la question",
      question_access: "Paramètre des accès",
      cluster_settings: "Paramètre de la grille",
      remove_question: "Supprimer la question",
      remove_cluster: "Supprimer la grille",
      remove_title: "Supprimer le titre",
      duplicate_cluster: "Dupliquer la grille",
      duplicate_question: "Dupliquer la question",
      tip: "Infobulle",
      display_mode: "Grille de réponse",
      save: "Enregistrer",
      save_success: "Questionnaire enregistré avec succès !",
      save_error: "Il y'a eu une erreur lors de l'enregistrement !",
      missing_error:
        "Attention il manque certains droits d'accès ou des titres aux questions !",
      informations: "Informations",
      deadlines_reminders: "Échéance & relances",
      advanced_parameter: "Paramètres avancés",
      company_visit: "Visite en entreprise",
      settings: "Paramètres",
      summary: "Synthèse",
      add_column: "Ajouter une colonne",
      add_row: "Ajouter une ligne",
      row_settings: "Qui rempli ?",
      skill_settings: "Gérer les compétences liées",
      access_missing: "Les droits d'accès sont manquants pour cette question",
      title_missing: "Vous devez renseigner un titre pour cette question",
      duplicate_row: "Dupliquer la ligne",
      remove_row: "Supprimer la ligne",
      remove_column: "Supprimer la colonne",
      empty_line: "Ligne sans titre",
      add_skill: "Ajouter cette compétence",
      skill_category_name: "Catégorie",
      skill_name: "Nom",
      coefficient: "Coefficient",
      empty_column: "Titre de la colonne",
      remove_field_skill: "Suppression de la compétence liée",
      field_skill_used: "Cette compétence est déjà liée à cette question",
      remove_field_skill_sub:
        "Êtes-vous sûr de vouloir supprimer la liaison de cette compétence avec la question ?",
      untitled_column: "Colonne sans titre",
      remove_row_warning: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
      remove_column_warning:
        "Êtes-vous sûr de vouloir supprimer cette colonne ?",
      untitled_questionnaire: "Formulaire sans titre",
      placeholder_description: "Ajouter votre description",
      access_profil: "Profils répondant à la question",
      field_skill: "Compétences liées à la question",
      search_skill: "Rechercher une compétence",
      opened_question_access: "Accès au questions ouvertes",
      opened_questions: "Questions ouvertes",
      opened_questions_type: "Type de questions ouvertes",
      grid_title: "Titre de la grille",
      comments_enabled: "Commentaires autorisés",
      comments_label: "Intitulé des commentaires",
      comments_type: "Type de champs commentaires",
      one_line: "Réponse courte",
      multi_line: "Zone de texte",
      maximal_mark: "Note maximum",
      display_mark: "Utiliser les notes",
      display_radar: "Afficher le radar",
      question_title: "Titre de la question",
      infobulle_content: "Contenu de l'infobulle",
      field_type_choose: "Choisissez un type",
      short_answer: "Réponse courte",
      long_answer: "Réponse longue",
      textarea: "Zone de texte",
      number: "Nombre",
      dropdown_list: "Liste déroulante",
      unique_choice: "Choix unique",
      multiple_choices: "Choix multiples",
      file: "Fichier",
      date: "Date",
      date_time: "Date et heure",
      remove_question_warning:
        "Êtes-vous sûr de vouloir supprimer cette question ?",
      option: "Option",
      untitled_cluster: "Grille sans titre",
      untitled_title: "Title",
      title_label: "Titre",
      remove_cluster_warning:
        "Êtes-vous sûr de vouloir supprimer cette grille ?",
      value: "Valeur",
      choose_option: "Choisissez une option",
      questionnaire_settings: "Paramétrage du questionnaire",
      confirm: "Confirmer",
      cancel: "Annuler",
      rights: "Droits",
      notes_summary: "Notes et synthèse",
      option_placeholder: "Option",
      value_placeholder: "Note",
      comment_placeholder: "Ajouter un commentaire",
      option_settings: "Configuration des options",
      columns: "Colonnes",
      active: "Actif",
    },
    category: {
      title: ({ plural }) => plural(["Catégorie", "Catégories"]),
    },
    webLink: {
      title: ({ plural }) => plural(["Lien utile", "Liens utiles"]),
    },
    trainingCourse: {
      title: ({ plural }) => plural(["Promotion", "Promotions"]),
    },
    skill: {
      title: ({ plural }) => plural(["Compétence", "Compétences"]),
      list: {
        title: "Configuration des compétences",
        empty:
          "Il n'y a pas encore de compétence sur cette formation, veuillez créer une première catégorie pour commencer",
      },
      actions: {
        module_actions: "Configuration",
        skill_actions: "Gérer les compétences",
        import_export_actions: "Import/Export",
        reminder_actions: "Relances",
      },
      name: "Nom",
      level: "Niveau requis",
      default: {
        not_acquired: "Non acquis",
        acquired: "Acquis",
        expert: "Expert",
        being_acquired: "En cours d'acquisition",
        insufficient_mastery: "Maîtrise insuffisante",
        partial_mastery: "Maîtrise partielle",
        average_mastery: "Maîtrise moyenne",
        good_mastery: "Maîtrise bonne",
        excellent_mastery: "Maîtrise excellente",
      },
    },
    skill_configuration: {
      title: "Configuration du module",
      success: "Configuration mis à jour avec succès !",
    },
    skill_generate: {
      title: "Générer un questionnaire à partir des compétences",
      success: "Questionnaire généré avec succès !",
    },
    skill_remove: {
      title: "Supprimer le référentiel (sans les questionnaires)",
      sub_title: "Êtes-vous sûr de vouloir supprimer le référentiel",
      success: "Référentiel supprimé avec succès !",
    },
    skill_import_france_competence: {
      title: "Importer le référentiel France Compétences",
      confirm:
        "Êtes-vous sûr de vouloir charger le référentiel France Compétences sur cette formation ?",
      success: "Référentiel importé avec succès !",
    },
    skill_export: {
      title: "Exporter les compétences",
      success: "Compétences exportées avec succès !",
    },
    skill_export_taught: {
      title: "Exporter les matières",
      success: "Matières exportées avec succès !",
    },
    skillSession: {
      title: ({ plural }) => plural(["Session", "Sessions"]),
    },
    skillCategory: {
      title: ({ plural }) => plural(["Catégorie", "Catégories"]),
    },
    skin: {
      title: ({ plural }) => plural(["Habillage", "Habillages"]),
      list: {
        title: "Habillage",
      },
    },
    gradebook: {
      title: ({ plural }) => plural(["Bulletin de note", "Bulletins de note"]),
    },
    triad: {
      title: ({ plural }) => plural(["Trinôme", "Trinômes"]),
      list: {
        name: "Nom",
        apprentice_name: "Nom de l'apprenti",
        tutor_name: "Nom du tuteur pédagogique",
        map_name: "Nom du tuteur entreprise",
        gestionnary_name: "Nom du gestionnaire",
      },
      questionnaire_triad_already_signed:
        "Attention, des signatures de \nquestionnaires sont rattachées \nà ce trinôme",
      usurp: {
        title: "Incarner",
      },
      login: {
        title: "Gérer l'envoi des identifiants",
      },
      delete: {
        tutor: "Supprimer ce tuteur pédagogique",
        map: "Supprimer ce tuteur entreprise",
        legal_responsible: "Supprimer ce responsable légal",
      },
      delete_member: {
        title: "Membre du trinôme",
      },
      edit: {
        legal_responsible: "Modifier ce responsable légal",
        tutor: "Modifier ce tuteur pédagogique",
      },
      contract: {
        title: "Gestion des contrats",
      },
      complete: {
        title: "Ajouter un membre au trinôme",
      },
      actions: {
        title: "Actions",
      },
      add_test: "trinôme test",
      add_test_success: "Trinôme test ajouté avec succès !",
      send_user_infos: "Gérer l'envoi des identifiants",
      send_welcome_email: "Envoyer l'email de bienvenue avec les identifiants",
      send_welcome_email_success:
        "Email(s) de bienvenue envoyé(s) avec succès !",
      reset_password: "Réinitialiser le mot de passe et renvoyer l'identifiant",
      reset_password_success:
        "Email(s) de réinitialisation de mot de passe envoyé(s) avec succès !",
      send_user_infos_message:
        "Cochez les utilisateurs et cliquez sur le type d'email que vous souhaitez leur envoyer",
      add_secondary_user: "Ajouter un utilisateur secondaire pour ce trinôme",
      add_secondary_user_success: "Utilisateur secondaire ajouté avec succès",
      remove_secondary_user: "Utilisateur secondaire supprimé avec succès",
    },
    studeaUser: {
      title: ({ plural }) => plural(["Utilisateur", "Utilisateurs"]),
    },
    crew: {
      title: ({ plural }) => plural(["Groupe", "Groupes"]),
      cannot_remove:
        "Vous ne pouvez pas supprimer un groupe qui contient des trinômes",
    },
    contract: {
      title: ({ plural }) => plural(["Contrat", "Contrats"]),
      empty: "Ce trinôme ne possède aucun contrat",
      dates: "Dates du contrat",
      company: "Entreprise",
      duration: "Durée",
      address: "Adresse",
      map: "MAP",
      manager: "Gestionnaire",
      contrat_type: "Type de contrat",
      rupture_date: "Date de rupture",
      rupture_reason: "Motif de rupture",
      months: "mois",
    },
    template: {
      title: ({ plural }) => plural(["Modèle", "Modèles"]),
    },
    lesson: {
      title: ({ plural }) => plural(["Cours", "Cours"]),
    },
    skillType: {
      title: ({ plural }) =>
        plural([
          "Type de compétence",
          "Types de compétences",
          "Type de compétences",
        ]),
    },
    subject: {
      title: ({ plural }) => plural(["Matière", "Matières"]),
    },
    unit: {
      title: ({ plural }) => plural(["Unité", "Unités"]),
    },
    planning: {
      title: ({ plural }) => plural(["Évènement", "Évènements"]),
      removeSelected: {
        title: "Supprimer les évènements sélectionnés",
        sub_title: "Êtes-vous sûr de vouloir supprimer ces évènements ?",
        sucess: "Évènements supprimés avec succès !",
      },
    },
    trainingCourseModuleTriad: {
      title: ({ plural }) => plural(["Module", "Modules"]),
    },
    absence: {
      title: ({ plural }) => plural(["Absence", "Absences"]),
      list: {
        title: "Assiduité",
      },
      config: {
        title: "Configuration",
        success: "Configuration modifiée avec succès !",
      },
      export: {
        full_list: "Liste complète des absences",
        apprentice_list: "Liste des absences par ",
        excel: "(excel)",
        pdf: "(pdf)",
        state: "État des absences (planning)",
      },
    },
    module: {
      active: {
        success: "Module activé avec succès !",
      },
      unactive: {
        success: "Module désactivé avec succès !",
      },
    },
    registration: {
      title: ({ plural }) => plural(["Inscription", "Inscriptions"]),
      list: {
        title: "Inscriptions",
      },
    },
    notepad: {
      title: ({ plural }) => plural(["Bloc-notes", "Blocs-notes"]),
    },
    note: {
      title: ({ plural }) => plural(["Note", "Notes"]),
    },
    document: {
      title: ({ plural }) => plural(["Document", "Documents"]),
      pedagogic_documents: "Documents pédagogiques",
      general_documents: "Documents généraux",
      shared_documents: "Documents partagés",
      shared_document_add: "Ajout d'un document partagé",
      shared_document_edit: "Modifier un document partagé",
      shared_document_list: "Liste des documents partagés",
      shared_document_info:
        "Ce document est partagé, pour le modifier veuillez \n vous rendre dans la liste des documents partagés",
    },
    "document/shared": {
      title: ({ plural }) => plural(["Document partagé", "Documents partagés"]),
    },
    documentExchange: {
      list: {
        title: "Échange de docs",
      },
    },
    messaging: {
      list: {
        title: "Messagerie",
      },
    },
    dashboard: {
      list: {
        title: "Tableau de bord",
      },
      signature_cancel_confirmation:
        "Êtes vous sûr(e) de vouloir annuler la signature ?",
      filter: "Filtrer",
      questionnaire: {
        unlocked_confirmation: "Voulez-vous débloquer ce questionnaire ?",
        locked_confirmation: "Voulez-vous bloquer ce questionnaire ?",
        unlocked_all_confirmation:
          "Voulez-vous débloquer tous les questionnaires de cette colonne ?",
        unlock_all_questionnaires:
          "Débloquer tous les questionnaires de cette colonne",
        unlock_questionnaire: "Débloquer ce questionnaire",
        lock_questionnaire: "Bloquer ce questionnaire",
        cancel_signature: "Annuler cette signature",
        success_all_unlock: "Questionnaires débloqués avec succès !",
        success_unlock: "Questionnaire débloqué avec succès !",
        success_lock: "Questionnaire bloqué avec succès !",
        success_cancel_signature: "Signature annulée avec succès !",
      },
      mail_relance: "Relances par mail",
      email_reminder: "Relancer par mail",
      export: {
        all_promotion_replies:
          "Exporter l'ensemble des réponses de la promotion",
        visit_date: "Exporter les dates de visite en entreprise",
        dashboard_excel: "Exporter le tableau de bord au format Excel",
        questionnaires_pdf: "Exporter les questionnaires",
      },
      roles: {
        alternate: "Alternant",
        educational_tutor: "Tuteur pédagogique",
        company_tutor: "Tuteur en entreprise",
        lea_manager: "Chargé de léa",
      },
    },
    gradeBookDashboard: {
      list: {
        title: "Tableau des notes",
      },
      export_dashboard_excel: "Exporter le tableau des notes au format Excel",
    },
    skillDashboard: {
      list: {
        title: "Tableau de bord des compétences",
      },
      export_dashboard_excel:
        "Exporter le tableau des compétences au format Excel",
      skill: {
        acquired: "Acquis",
        in_progress: "En cours",
        not_acquired: "Non acquis",
      },
    },
    export: {
      list: {
        title: "Exports",
      },
    },
    mail: {
      list: {
        title: "Suivi des mails",
      },
    },
    history: {
      list: {
        title: "Historique des connexions",
      },
    },
    assiduite: "Gestion de l'assiduité",
    subscriptions_dashboard: "Tableau de bord des inscriptions",
    import: {
      modele: "Modèle de fichier à télécharger",
      triad_new_file:
        "Attention, un nouveau modèle est disponible depuis le 18/09/2023.\n" +
        "          Merci de le télécharger et de l'utiliser pour charger vos trinômes.",
      triad_update:
        "Attention, seuls les membres manquants d'un trinôme seront mis à jour. Aucune donnée (nom, prénom ou email) d'un utilisateur existant ne sera mise à jour.",
      thanks:
        "Merci de ne pas modifier et / ou supprimer les colonnes du modèle",
    },
    download_file_here: "Télécharger le fichier ICI",
    session: {
      from: "Du",
      to: "au",
    },
  },
  add_session: "Ajouter une session",
};
