<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="loader-block">
        <div v-if="subjects.length">
          <div v-for="subject in subjects" :key="subject" class="widget">
            <div class="widget-heading flex-column flex-md-row">
              <h2 class="textStyle">{{ subject.name }}</h2>
              <div class="mt-2 mt-md-0">
                <router-link
                  :to="{
                    name: 'studeaManagerLessonAdd',
                    params: { alias: 'lesson' },
                    query: {
                      set: JSON.stringify([
                        {
                          name: 'subject',
                          value: subject.id,
                        },
                      ]),
                    },
                  }"
                  class="list__button"
                  v-tooltip="{
                    title: $tc('global.add.title', 1, {
                      name: this.$tc(
                        'studea_manager.lesson.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                >
                  <FontAwesomeIcon icon="fa-light fa-plus" />
                </router-link>
                <router-link
                  :to="{
                    name: 'studeaManagerSubjectEdit',
                    params: { id: subject.id, alias: 'subject' },
                    query: { backPath: 'studeaManagerLessonList' },
                  }"
                  class="list__button -skill"
                  v-tooltip="{
                    title: $tc('global.edit.title', 2, {
                      name: this.$tc(
                        'studea_manager.subject.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                >
                  <FontAwesomeIcon icon="fa-light fa-edit" />
                </router-link>
                <button
                  v-tooltip="{
                    title: $tc('global.delete.title', 2, {
                      name: this.$tc(
                        'studea_manager.subject.title',
                        1,
                      ).toLowerCase(),
                    }),
                  }"
                  class="list__button -skill"
                  @click="remove(subject.id, 'subject')"
                >
                  <FontAwesomeIcon icon="fa-light fa-trash" />
                </button>
              </div>
            </div>
            <div class="widget-content">
              <div class="list" v-if="Object.keys(subject.lessons).length">
                <div class="list__lesson row -heading">
                  <div class="col-md-11 col-9">
                    {{ $t("global.label.name") }}
                  </div>
                  <div class="col-md-1 col-3"></div>
                </div>
                <VueDraggableNext
                  :animation="200"
                  :delay="200"
                  :delay-on-touch-only="true"
                  :group="{ name: 'lessons' }"
                  :list="subject.lessons"
                  @change="sort($event, subject.id)"
                >
                  <div
                    v-for="lesson in subject.lessons"
                    :key="lesson"
                    class="list__lesson row grip"
                  >
                    <div class="col-md-11 col-12 mb-2 mb-md-0">
                      {{ lesson.title }}
                    </div>
                    <div class="col-md-1 col-4 pe-0">
                      <div class="list__action">
                        <router-link
                          :to="{
                            name: 'studeaManagerLessonEdit',
                            params: { id: lesson.id, alias: 'lesson' },
                          }"
                          class="list__button"
                          v-tooltip="{
                            title: $tc('global.edit.title', 1, {
                              name: this.$tc(
                                'studea_manager.lesson.title',
                                1,
                              ).toLowerCase(),
                            }),
                          }"
                        >
                          <FontAwesomeIcon icon="fa-light fa-edit" />
                        </router-link>
                        <button
                          class="list__button"
                          v-tooltip="{
                            title: $t('global.delete.title', 1, {
                              name: this.$tc('studea_manager.lesson.title', 1),
                            }),
                          }"
                          @click="remove(lesson.id, 'lesson')"
                        >
                          <FontAwesomeIcon icon="fa-light fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </VueDraggableNext>
              </div>
              <div class="mt-2" v-else>
                <div class="textStyle fst-italic">
                  {{
                    $tc("global.empty.title", 1, {
                      name1: this.$tc(
                        "studea_manager.subject.title",
                        1,
                      ).toLowerCase(),
                      name2: this.$tc(
                        "studea_manager.lesson.title",
                        1,
                      ).toLowerCase(),
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">
              {{
                $t("global.list.title", {
                  name: this.$tc(
                    "studea_manager.lesson.title",
                    1,
                  ).toLowerCase(),
                })
              }}
            </h2>
          </div>
          <div class="widget-content">
            <div class="text-center">
              <div class="noResult blockStyle">
                {{ $t("No data available") }}<br />
                {{ $t("Please click on the add button") }}
              </div>
              <router-link
                :to="{
                  name: 'studeaManagerSubjectList',
                  params: { alias: 'subject' },
                }"
                class="btn btn-primary mb-3"
                >{{
                  $t("global.list.title", {
                    name: this.$tc(
                      "studea_manager.subject.title",
                      2,
                    ).toLowerCase(),
                  })
                }}
              </router-link>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :collapsable="false"
            collapseId="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "LessonListView",
  components: {
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      subjects: [],
      loading: false,
      actions: [
        {
          translation: "global.list.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.subject.title", 2).toLowerCase(),
          },
          icon: "book",
          type: "success",
          actionType: "router",
          action: "studeaManagerSubjectList",
          cfaStyle: true,
          params: { alias: "subject" },
        },
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.lesson.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerLessonAdd",
          cfaStyle: false,
          params: { alias: "lesson" },
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init(loading = true) {
      this.loading = loading;
      studeaManagerManager
        .list("lesson", this.$route.params.trainingCourseId)
        .then((res) => {
          this.subjects = res;

          if (!this.subjects.length) {
            this.actions.pop();
          }

          this.loading = false;
        });
    },

    sort(event, subjectId) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        studeaManagerManager
          .sort("lesson", this.$route.params.trainingCourseId, id, position)
          .then(() => {
            this.init(false);
          });
      } else if (event.added) {
        const id = event.added.element.id;
        studeaManagerManager
          .move("lesson", this.$route.params.trainingCourseId, id, subjectId)
          .then(() => {
            this.init(false);
          });
      }
    },

    remove(id, alias) {
      studeaManagerManager
        .remove(
          alias,
          this.$route.params.trainingCourseId,
          id,
          alias === "lesson" ? 1 : 2,
        )
        .then(() => {
          this.init();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.grip {
  cursor: grab;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__lesson {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}
</style>
