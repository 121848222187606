<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="widget">
        <div class="widget-heading">
          <h2 class="textStyle">{{ $t("admin.home.title3") }}</h2>
        </div>
        <div class="widget-content">
          <div class="d-flex">
            <div class="me-3 textStyle">
              OFAlink
              <span class="badge bg-success ms-2">{{
                $t("admin.home.active")
              }}</span>
            </div>
            |
            <div class="ms-3 textStyle">
              SSO ({{ $t("admin.home.unique_connection") }})
              <span class="badge bg-success ms-2">{{
                $t("admin.home.active")
              }}</span>
              <div>{{ $t("admin.home.apprentices_mentor") }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="widget widget-summary-1">
        <div class="widget-heading">
          <h2 class="textStyle">{{ $t("admin.home.title4") }}</h2>
        </div>
        <div class="widget-content">
          <div class="stats">
            <Splide
              :key="key"
              :has-track="false"
              :options="splideOptions"
              aria-label="Carousel de statistiques"
            >
              <SplideTrack>
                <SplideSlide>
                  <NumberCardChart :stats="stats" />
                </SplideSlide>

                <SplideSlide>
                  <ApexChart
                    :options="chartOptions"
                    :series="chartSeries"
                    height="325"
                    type="area"
                  />
                </SplideSlide>
              </SplideTrack>
              <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--prev textStyle">
                  <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                </button>
                <button class="splide__arrow splide__arrow--next textStyle">
                  <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                </button>
              </div>
            </Splide>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <NewsBlockComponent :loading="loading" :news="newsFiltered" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import NewsBlockComponent from "@/components/blocks/NewsBlockComponent.vue";
import ApexChart from "vue3-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import NumberCardChart from "@/components/charts/NumberCardChart.vue";

export default {
  name: "AdminHomeView",
  data() {
    return {
      splideOptions: {
        type: "loop",
        perPage: 1,
        width: "100%",
        arrows: true,
        pagination: false,
        autoplay: false,
        drag: false,
      },
      key: 0,
      stats: [],
      chartOptions: {
        chart: {
          fontFamily: "Nunito, sans-serif",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        title: {
          display: true,
          text: "Taux de remplissage annuel",
          font: { weight: "normal", fontFamily: "Nunito, sans-serif" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, curve: "smooth", width: 2, lineCap: "square" },
        dropShadow: {
          enabled: true,
          opacity: 0.2,
          blur: 10,
          left: -7,
          top: 22,
        },
        colors: this.isDark ? ["#2196f3", "#e7515a"] : ["#1b55e2", "#e7515a"],
        markers: {
          discrete: [
            {
              seriesIndex: 0,
              dataPointIndex: 6,
              fillColor: "#1b55e2",
              strokeColor: "#fff",
              size: 7,
            },
            {
              seriesIndex: 1,
              dataPointIndex: 5,
              fillColor: "#e7515a",
              strokeColor: "#fff",
              size: 7,
            },
          ],
        },
        labels: [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Jui",
          "Jui",
          "Aoû",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        xaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          crosshairs: { show: true },
          labels: {
            offsetX: 0,
            offsetY: 5,
            style: {
              fontSize: "12px",
              fontFamily: "Nunito, sans-serif",
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (value) {
              return value + "%";
            },
            offsetX: 0,
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: "Nunito, sans-serif",
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        grid: {
          borderColor: this.isDark ? "#191e3a" : "#e0e6ed",
          strokeDashArray: 5,
          xaxis: { lines: { show: true } },
          yaxis: { lines: { show: false } },
          padding: { top: 0, right: 0, bottom: 0, left: 0 },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: 0,
          fontSize: "16px",
          fontFamily: "Nunito, sans-serif",
          markers: {
            width: 10,
            height: 10,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: { horizontal: 20, vertical: 5 },
        },
        tooltip: { theme: "dark", marker: { show: true }, x: { show: false } },
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: this.isDark ? 0.19 : 0.28,
            opacityTo: 0.05,
            stops: this.isDark ? [100, 100] : [45, 100],
          },
        },
      },
      chartSeries: [
        {
          name: "2023",
          data: [25, 35, 60, 33, 75, 90, 50, 40, 52, 60, 65, 85, 65],
        },
        {
          name: "2024",
          data: [15, 25, 50, 45, 60, 65, 38, 50, 70, 30, 75, 75, 90],
        },
      ],
      newsFiltered: [],
      loading: true,
    };
  },
  mounted() {
    this.init();
    if (
      this.apprenticeProfileLabel &&
      this.apprenticeProfileLabel.titlePlural &&
      this.apprenticeMasterProfileLabel &&
      this.apprenticeMasterProfileLabel.titlePlural &&
      this.tutorProfileLabel &&
      this.tutorProfileLabel.titlePlural
    ) {
      this.initStats();
    }
  },
  computed: {
    ...mapState({
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
      isDark: (state) => state.this.isDark_mode,
      windowWidth: (state) => state.windowWidth,
      sideBarOpened: (state) => state.sideBarOpened,
      news: (state) => state.news.news,
      apprenticeProfileLabel: (state) => state.platform.apprenticeProfileLabel,
      tutorProfileLabel: (state) => state.platform.tutorProfileLabel,
      apprenticeMasterProfileLabel: (state) =>
        state.platform.apprenticeMasterProfileLabel,
    }),
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 992) {
        this.render();
      }
    },
    sideBarOpened() {},
    apprenticeProfileLabel() {
      this.initStats();
    },
    tutorProfileLabel() {
      this.initStats();
    },
    apprenticeMasterProfileLabel() {
      this.initStats();
    },
  },
  methods: {
    initStats() {
      this.stats = [
        {
          label: "Formations et promotions actives",
          datas: [
            {
              label: "Formation / UFA",
              total: 59,
            },
            {
              label: "Promotions",
              total: 92,
            },
            {
              label: "Trinômes",
              total: 1381,
            },
          ],
        },
        {
          label: "Utilisateurs sur promotions actives",
          datas: [
            {
              label: "Utilisateurs",
              total: 4063,
              connected: 89.4,
              unconnected: 10.6,
            },
            {
              label:
                this.apprenticeProfileLabel &&
                this.apprenticeProfileLabel.titlePlural
                  ? this.apprenticeProfileLabel.titlePlural
                  : this.$t("apprentices"),
              total: 1381,
              connected: 90.6,
              unconnected: 9.4,
              download: true,
            },
            {
              label:
                this.tutorProfileLabel && this.tutorProfileLabel.titlePlural
                  ? this.tutorProfileLabel.titlePlural
                  : this.$t("tutors"),
              total: 1223,
              connected: 97.6,
              unconnected: 2.4,
              download: true,
            },
            {
              label:
                this.apprenticeMasterProfileLabel &&
                this.apprenticeMasterProfileLabel.titlePlural
                  ? this.apprenticeMasterProfileLabel.titlePlural
                  : this.$t("apprenticeMasters"),
              total: 1372,
              connected: 81.2,
              unconnected: 18.8,
              download: true,
            },
            {
              label: "Chargés de Livret",
              total: 87,
              connected: 86.2,
              unconnected: 13.8,
              download: true,
            },
          ],
        },
      ];
    },
    render() {
      setTimeout(() => {
        this.key++;
      }, 600);
    },

    init() {
      this.loading = true;
      this.$store.dispatch("fetchNews").then(() => {
        this.loading = false;
        this.newsFiltered = this.news.slice(0, 3);
      });
    },
  },
  components: {
    NumberCardChart,
    FontAwesomeIcon,
    NewsBlockComponent,
    ApexChart,
    Splide,
    SplideSlide,
    SplideTrack,
  },
};
</script>

<style lang="scss" scoped>
.stats {
  padding: 0 30px;
}

.splide__arrow {
  background: none;
  font-size: 24px;

  &--prev {
    left: -50px;
  }

  &--next {
    right: -50px;
  }
}
</style>
