<template>
  <div
    class="position-relative d-flex align-items-center justify-content-center"
  >
    <div
      class="spinner-border text-primary align-self-center loader me-3"
    ></div>
    <span class="textStyle fw-bold">{{ $t("login_in_progress") }}</span>
  </div>
</template>

<script>
import { loginManager } from "@/services/security/login-manager";
import { mapState } from "vuex";

export default {
  name: "LayoutView",
  components: {},
  data() {
    return {
      loading: true,
      token: null,
      refreshToken: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      trainingCourses: (state) => state.trainingCourse.trainingCourseList,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.token = this.$route.query.token;
      this.refreshToken = this.$route.query.refreshToken;
      window.localStorage.removeItem("studea-token");
      window.localStorage.removeItem("studea-refreshToken");
      window.sessionStorage.removeItem("studea-token");
      window.sessionStorage.removeItem("studea-refreshToken");
      this.$store.dispatch("initStore");
      loginManager.setToken(
        this.token,
        this.refreshToken,
        loginManager.getApiPath(),
      );
      window.localStorage.removeItem("studea-oldTriad");
      window.localStorage.removeItem("studea-oldTrainingCourse");

      this.$store.commit("SET_APP_LOADING", true);
      this.$store.dispatch("fetchUserInfos").then(() => {
        this.$store.dispatch("fetchTrainingCourseList", {}).then(() => {
          if (
            this.user.roles &&
            this.user.roles.includes("ROLE_STUDEA_ADMIN")
          ) {
            this.$store.commit("SET_APP_LOADING", false);
            window.localStorage.setItem(
              "studea-returnPathName",
              JSON.stringify({ name: "TrainingCourseList" }),
            );
            this.$router.push({ name: "adminHome" });
          } else if (Object.keys(this.trainingCourses).length > 1) {
            this.$store.commit("SET_APP_LOADING", false);
            this.$router.push({ name: "TrainingCourseList" });
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
