import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { triadManager } from "@/services/triad/triad-manager";
import store from "@/store";

const state = {
  trainingCourseList: {},
  trainingCourse: {},
  infos: {},
};

const mutations = {
  SET_TRAINING_COURSE_LIST(state, list) {
    state.trainingCourseList = list;
  },

  SET_TRAINING_COURSE_LOADING(state, value) {
    state.trainingCourse["loading"] = value;
  },

  SET_TRAINING_COURSE_TRIADS(state, list) {
    if (state.trainingCourse && state.trainingCourse.id) {
      const trainingCourse = getters.findTrainingCourse(
        state.trainingCourseList,
        state.trainingCourse.id,
      );
      trainingCourse["triads"] = list;
    }
  },
  SET_TRAINING_COURSE(state, trainingCourse) {
    if (trainingCourse.id) {
      window.localStorage.setItem(
        "studea-oldTrainingCourse",
        trainingCourse.id,
      );
    }
    state.trainingCourse = trainingCourse;
  },
  TOGGLE_TRAINING_COURSE(state, id) {
    if (state.trainingCourseList.length) {
      state.trainingCourse = getters.findTrainingCourse(
        state.trainingCourseList,
        id,
      );
      if (!state.trainingCourse) {
        state.trainingCourse = state.trainingCourseList[0];
      }

      window.localStorage.setItem(
        "studea-oldTrainingCourse",
        state.trainingCourse.id,
      );
    }
  },
  INIT_TRAINING_COURSE_MODULE(state) {
    state.trainingCourseList = {};
    state.trainingCourse = {};
  },

  SET_TRAINING_COURSE_INFOS(state, triad) {
    let infos = [];
    if (triad.trainingCourse && triad.trainingCourse.displayPresentation) {
      infos.push({
        label: triad.trainingCourse.blocNamePresentation,
        content: triad.trainingCourse.presentation,
        type: "presentation",
      });
    }
    if (triad.trainingCourse && triad.trainingCourse.displayContact) {
      infos.push({
        label: triad.trainingCourse.blocNameContact,
        content: triad.trainingCourse.contact,
        type: "contact",
      });
    }
    if (triad.trainingCourse && triad.trainingCourse.displayPartner) {
      infos.push({
        label: triad.trainingCourse.blocNamePartner,
        content: triad.trainingCourse.partner,
        type: "partner",
      });
    }
    if (triad.trainingCourse && triad.trainingCourse.displayCommunication) {
      infos.push({
        label: triad.trainingCourse.blocNameCommunication,
        content: triad.trainingCourse.communication,
        type: "communication",
      });
    }

    state.infos = infos;
  },
};

const getters = {
  findTrainingCourse(list, id) {
    if (list && typeof list === "object") {
      return list.find((item) => item.id === id);
    }
  },
};

const actions = {
  fetchTrainingCourseList({ commit, dispatch }, payload) {
    if (store.state.isInternet) {
      return trainingCourseManager
        .loadTrainingCourseList(payload.archive, payload.search)
        .then((list) => {
          if (list) {
            commit("SET_TRAINING_COURSE_LIST", list);
            if (
              Object.keys(list).length &&
              !window.localStorage.getItem("studea-oldTrainingCourse")
            ) {
              commit("SET_TRAINING_COURSE", list[0]);
            } else {
              commit(
                "TOGGLE_TRAINING_COURSE",
                parseInt(
                  window.localStorage.getItem("studea-oldTrainingCourse"),
                ),
              );
            }
          }

          if (!payload.search && Object.keys(list).length) {
            dispatch("fetchTrainingCourseTriads", {
              id: state.trainingCourse.id,
              toggle: true,
              archive: payload.archive ? payload.archive : 1,
            });
          }
        });
    }
  },
  fetchTrainingCourseTriads({ commit }, payload) {
    if (payload.toggle) {
      commit("TOGGLE_TRAINING_COURSE", payload.id);
    }

    commit("SET_TRAINING_COURSE_LOADING", true);

    return triadManager
      .loadTriadList(payload.id, payload.archive)
      .then((list) => {
        commit("SET_TRAINING_COURSE_TRIADS", list);
        commit("SET_TRAINING_COURSE_LOADING", false);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
