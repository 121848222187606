export default {
  page: {
    home: "Accueil",
    search: "Recherche",
    skills: "Les Compétences",
    skills_stats: "Les statistiques des compétences",
    dashboard: "Tableau de bord",
    questionnaires: "Les questionnaires",
    weblinks: "Vos liens utiles",
    gradebook: "Vos bulletins de notes",
    attestations: "Vos attestations de présence",
    message: "Messagerie",
    document: "Vos documents partagés",
    document_add: "Ajouter un document",
    document_add_note: "Ajouter une note",
    lessons: "Vos cours",
    timetable: "Votre emploi du temps",
    absence: "Gestion de l'assiduité",
    profile: "Profil",
    questionnaire: "Questionnaire",
    training_course_list: "Liste des formations",
    login: "Connexion",
    notepad: "Bloc-notes",
    new_message: "Nouveau message",
    new_password: "Nouveau mot de passe",
    reset_password: "Réinitialiser son mot de passe",
    error: "Erreur",
  },
};
