<template>
  <div class="widget faq">
    <div class="widget-heading flex-md-row flex-column">
      <div>
        <h2 class="textStyle h-auto">
          {{
            $t("triadTrainingList") +
            (design && design.label
              ? design.label.toLowerCase()
              : $t("promotion"))
          }}
        </h2>
        <span class="fst-italic">
          {{ textInfoTrainingCourse() }}
        </span>
      </div>
      <div class="widget-search d-flex mt-2 mt-md-0">
        <ModelComponent :model="search" @input="filter" />
        <button
          class="btn btnStyle btn-primary ms-2"
          :key="key"
          v-tooltip="{
            title: this.archiveTooltip,
          }"
          @click="archive(archived ? 1 : 2)"
        >
          <FontAwesomeIcon v-if="!archived" icon="fa-light fa-archive" />
          <FontAwesomeIcon v-else icon="fa-light fa-badge-check" />
        </button>
      </div>
    </div>
    <div class="widget-content faq-layouting position-relative">
      <div class="fq-tab-section">
        <div
          id="trainingCourseList"
          class="accordion"
          v-if="Object.keys(trainingCourses).length"
        >
          <div
            v-for="trainingCourse in trainingCourses"
            :key="trainingCourse.id"
            class="card"
          >
            <div class="card-header" role="tab">
              <div
                :aria-controls="'collapse-' + trainingCourse.id"
                :aria-expanded="false"
                :data-bs-target="'#collapse-' + trainingCourse.id"
                class="mb-0"
                data-bs-toggle="collapse"
                role="menu"
                @click="loadTriads(trainingCourse)"
              >
                <span class="faq-q-title textStyle">
                  <span :class="{ green: !this.archived, red: this.archived }"
                    ><FontAwesomeIcon
                      :icon="
                        'fa-light fa-' + (this.archived ? 'archive' : 'check')
                      "
                  /></span>
                  {{ trainingCourse.displayName
                  }}<span v-if="trainingCourse.loading"
                    ><span
                      class="me-1 spinner-border loading align-self-center loader-sm"
                    ></span>
                    <span class="d-none d-md-inline"
                      >Chargement des trînomes...</span
                    ></span
                  ></span
                >
                <div
                  v-if="trainingCourse.isStudeaManager"
                  class="like-faq d-flex align-items-center"
                  @click="$event.stopPropagation()"
                >
                  <span class="d-inline-block triadNb"
                    >{{ trainingCourse.nbTriad }} livrets</span
                  >
                  <router-link
                    :to="{
                      name: 'studeaManagerHome',
                      params: { trainingCourseId: trainingCourse.id },
                    }"
                    class="settings d-none"
                    v-tooltip="{
                      title: $t('studea_manager.subscriptions_dashboard'),
                    }"
                  >
                    <FontAwesomeIcon
                      class="icon"
                      icon="fa-light fa-pen-field"
                    />
                  </router-link>
                  <router-link
                    :to="{
                      name: 'studeaManagerHome',
                      params: { trainingCourseId: trainingCourse.id },
                    }"
                    class="settings"
                    v-tooltip="{
                      title: this.settingsTooltip,
                    }"
                  >
                    <FontAwesomeIcon class="icon" icon="fa-light fa-gear" />
                  </router-link>
                </div>
              </div>
            </div>
            <div
              v-if="!loading"
              :id="'collapse-' + trainingCourse.id"
              :aria-labelledby="'collapse-' + trainingCourse.id"
              class="collapse"
              :class="{
                show:
                  (currentTrainingCourse &&
                    trainingCourse.id === currentTrainingCourse.id) ||
                  (trainingCourse.triads &&
                    Object.keys(trainingCourse.triads).length &&
                    isSearching),
                archive: this.archived,
              }"
              data-bs-parent="#trainingCourseList"
            >
              <TriadUsersListComponent
                :search="false"
                :trainingCourse="trainingCourse"
              />
            </div>
          </div>
        </div>
        <div v-else>
          {{
            archived
              ? $t("dashboard.no_training_course_archived")
              : $t("dashboard.no_training_course_actived")
          }}
        </div>
      </div>
      <BlockLoader :loading="listLoading" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TriadUsersListComponent from "@/components/triad/TriadUsersListComponent.vue";
import ModelComponent from "@/components/form/ModelComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { i18n } from "@/i18n";

export default {
  name: "TrainingCourseListBlockComponent",
  components: {
    BlockLoader,
    FontAwesomeIcon,
    ModelComponent,
    TriadUsersListComponent,
  },
  props: {
    adminButton: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      loadingId: null,
      isSearching: false,
      archived: false,
      archiveTooltip: "",
      settingsTooltip: "",
      key: 0,
      search: {
        vars: {
          block_prefixes: ["", "text"],
          name: "training-course-search",
          id: "training-course-search",
          attr: {
            placeholder: this.$t("dashboard.triad_search_placeholder"),
          },
        },
      },
    };
  },
  mounted() {
    this.initTooltipTitle("settings");
    this.initTooltipTitle(!this.archived ? "archived" : "active");
  },
  methods: {
    loadTriads(trainingCourse) {
      if (
        trainingCourse &&
        (!trainingCourse.triads || !Object.keys(trainingCourse.triads).length)
      ) {
        this.loading = true;
        window.localStorage.setItem(
          "studea-oldTrainingCourse",
          trainingCourse.id,
        );
        this.$store
          .dispatch("fetchTrainingCourseTriads", {
            id: trainingCourse.id,
            toggle: true,
            archive: this.archived ? 2 : 1,
          })
          .then(() => {
            this.loading = false;
          });
      }
    },

    filter(value) {
      if (value && value.length >= 2) {
        this.isSearching = true;
        this.search.vars.value = value;
      }
      if (this.isSearching) {
        this.listLoading = true;
        this.$store
          .dispatch("fetchTrainingCourseList", {
            search: value,
            archive: 1,
          })
          .then(() => {
            this.listLoading = false;
          });
      }
    },

    archive(isArchived) {
      this.archived = isArchived === 2;
      this.initTooltipTitle(!this.archived ? "archived" : "active");
      if (this.search.vars.value) {
        this.isSearching = true;
      }
      this.listLoading = true;
      this.$store
        .dispatch("fetchTrainingCourseList", {
          search: this.search.vars.value,
          archive: isArchived,
        })
        .then(() => {
          this.listLoading = false;
        });
    },

    textInfoTrainingCourse() {
      let label = "";
      const number = Object.keys(this.trainingCourses).length;
      const plural = number ? 2 : 1;
      const designLabel = this.design && this.design.label;
      const prefix = designLabel === "Groupe" ? "male_" : "female_";

      label = this.$tc("youHaveAccess", 1, {
        number: number,
        label: designLabel
          ? designLabel.toLowerCase() + (number ? "s" : "")
          : this.$tc("promotion", plural),
        active: !this.archived
          ? this.$tc(prefix + "active", plural)
          : this.$tc(prefix + "archived", plural),
      });
      return label;
    },

    initTooltipTitle(type) {
      if (this.design && this.design.label) {
        const plural = this.design.label.toLowerCase() === "groupe" ? 2 : 1;
        if (type === "settings") {
          this.settingsTooltip =
            i18n.global.tc("studea_manager.home.title", plural) +
            this.design.label.toLowerCase();
        }
        if (type === "archived") {
          this.archiveTooltip = i18n.global.tc(
            "dashboard.archived_training_course",
            plural,
            {
              label: this.design.label.toLowerCase(),
            },
          );
        }
        if (type === "active") {
          this.archiveTooltip = i18n.global.tc(
            "dashboard.unarchived_training_course",
            plural,
            {
              label: this.design.label.toLowerCase(),
            },
          );
        }
      }
      this.key++;
    },
  },
  computed: {
    ...mapState({
      trainingCourses: (state) => state.trainingCourse.trainingCourseList,
      currentTrainingCourse: (state) => state.trainingCourse.trainingCourse,
      triads: (state) => state.triad.triads,
      user: (state) => state.user.user,
      design: (state) => state.platform.design,
    }),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  #trainingCourseList {
    display: flex;
    flex-direction: column;
  }

  .loading {
    margin-left: 20px;
    width: 15px;
    height: 15px;
  }

  .settings {
    width: 35px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      font-size: 20px;
      color: $green;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: var(--primary-color);

      @include up-to-md {
        height: 0;
        width: 0;
      }
    }
  }

  .collapse {
    &.archive {
      background: $light-red;
    }
  }
}

.triadNb {
  font-size: 12px;
  font-style: italic;
  margin-right: 5px;
}

.faq-q-title {
  @include up-to-md {
    justify-content: flex-start !important;
  }

  .green {
    @include up-to-md {
      margin-right: 5px;
    }
  }
}

.like-faq {
  justify-content: space-between;
  @include up-to-md {
    margin-top: 15px;
    margin-left: 18px;
    justify-content: inherit;
  }
}
</style>
