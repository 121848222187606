<template>
  <div id="pdf" class="widget">
    <div class="widget-heading">
      <h2>{{ $t("actions") }}</h2>
    </div>
    <div class="widget-content">
      <button
        class="btn btn-primary mb-2 me-2 btnStyle"
        type="button"
        v-if="questionnaire.userCanExportPdf"
        @click="downloadPdf()"
      >
        <FontAwesomeIcon icon="fa-light fa-file-pdf" />
        {{ $t("downloadPdf") }}
      </button>
      <button
        class="btn btn-primary mb-2 me-2 btnStyle"
        type="button"
        @click="downloadPdf(1)"
      >
        <FontAwesomeIcon icon="fa-light fa-file-pdf" />
        {{ $t("downloadPdfModel") }}
      </button>
      <div
        v-if="hasToSign() && questionnaire.userCanExportPdf"
        class="line"
      ></div>
      <button
        v-if="hasToSign()"
        class="btn btn-success mb-2 me-2"
        type="button"
        :disabled="loading"
        @click="save(3)"
      >
        <FontAwesomeIcon icon="fa-light fa-signature" />
        {{ $t("sign_action") }}
      </button>
      <button
        v-if="
          questionnaire.useComments &&
          hasToSign() &&
          (!questionnaire.userReply ||
            (questionnaire.userReply && questionnaire.userReply.status === 1))
        "
        class="btn btn-info mb-2 me-2"
        type="button"
        :disabled="loading"
        @click="save(2)"
      >
        <FontAwesomeIcon icon="fa-light fa-share" />
        {{ $t("sharedDraft") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";

export default {
  name: "QuestionnaireControlBlockComponent",
  components: { FontAwesomeIcon },
  computed: {
    ...mapState({
      questionnaire: (state) => state.questionnaire.questionnaire,
      loading: (state) => state.questionnaire.questionnaireLoading,
      triad: (state) => state.triad.triad,
      model: (state) => state.questionnaire.model,
    }),
  },
  methods: {
    downloadPdf(model = 0) {
      questionnaireManager.getPdf(this.triad.id, this.questionnaire.id, model);
    },
    save(replyStatus) {
      var message = "";
      this.$emit("isLoading", true);
      switch (replyStatus) {
        case 1:
          message = this.$t("draftSuccess");
          break;
        case 2:
          message = this.$t("sharedSuccess");
          break;
        case 3:
          message = this.$t("signedSuccess");
          break;
      }
      questionnaireManager
        .saveQuestionnaire(replyStatus, message, this.$t("signed_error"))
        .then(() => {
          this.$emit("isLoading", false);
        });
    },

    hasToSign() {
      return !this.questionnaire.message && this.questionnaire.userHasToSign;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;

  svg {
    margin-right: 8px;
  }
}

.line {
  background: $light-grey;
  width: 100px;
  height: 1px;
  margin: 10px auto 15px auto;
}
</style>
