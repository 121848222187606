<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="widget">
        <div class="widget-heading">
          <h2 class="textStyle">{{ $t("admin.news.list.title") }} STUDEA</h2>
          <div
            class="d-flex align-items-center"
            v-tooltip="{ title: 'Activer les notifications' }"
          >
            <FontAwesomeIcon class="iconStyle fs-5" icon="fa-light fa-bell" />
            <SwitchTypeComponent :model="notifModel" />
          </div>
        </div>
        <div class="widget-content d-flex pt-4">
          <div class="container-fluid px-0">
            <div class="row">
              <div
                class="col-xl-4 col-lg-6 col-12"
                v-for="item in limitedNewList"
                :key="item"
              >
                <NewsCardComponent :news="item"></NewsCardComponent>
              </div>
              <BlockLoader :loading="loading"></BlockLoader>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <PaginationBlock
                  v-if="!loading"
                  :data="news"
                  :offset="offset"
                  :sliceLimit="sliceLimit"
                  :limit="limit"
                  @emit-data-list="handleNewsList"
                >
                </PaginationBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCardComponent from "@/components/blocks/NewsCardComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { mapState } from "vuex";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import PaginationBlock from "@/components/table/PaginationComponent.vue";

export default {
  name: "NewsListView",
  components: {
    BlockLoader,
    SwitchTypeComponent,
    NewsCardComponent,
    PaginationBlock,
  },
  data() {
    return {
      notifModel: {
        vars: {
          name: "notif",
          id: "notif",
          block_prefixes: ["", "switch"],
        },
      },
      loading: true,
      limitedNewList: [],
      offset: 0,
      limit: 12,
      sliceLimit: 12,
    };
  },
  mounted() {
    this.$store.dispatch("fetchNews").then(() => {
      this.loading = false;
      this.createLimitedNewsList(this.limit);
    });
  },

  computed: {
    ...mapState({
      news: (state) => state.news.news,
    }),
  },
  methods: {
    createLimitedNewsList(limit) {
      return (this.limitedNewList = this.news.slice(this.offset, limit));
    },
    handleNewsList(value) {
      this.limitedNewList = value;
    },
  },
};
</script>
<style lang="scss" scoped></style>
