<template>
  <template v-if="tabs && Object.keys(tabs).length">
    <FormTabsBlockComponent
      :loading="loading"
      :tabs="tabs"
      :invalidTab="invalidTab"
      :hideCancel="true"
      :removeSubmit="true"
      :keepHideValue="true"
    />
    <div class="widget">
      <SubmitTypeComponent :model="submitModel" @submitForm="submit()" />
    </div>
  </template>
  <template v-if="helpDocument">
    <FormBlockComponent
      :models="models"
      :title="$t('admin.help_settings')"
      :loading="loading"
      @submit="submitModels()"
      @formCancel="cancel()"
    />
  </template>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { formValidator } from "@/services/form/form-validator";
import { adminManager } from "@/services/admin/admin-manager";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";

export default {
  name: "DesignEditView",
  data() {
    return {
      trainingCoursesDatas: {},
      loading: false,
      tabs: [],
      models: [],
      params: {},
      invalidTab: null,
      helpDocument: false,
      submitModel: {
        vars: {
          block_prefixes: ["", "submit"],
          hideCancel: true,
          display: true,
        },
      },
    };
  },
  watch: {
    "$route.query.helpDocument"() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.helpDocument = this.$route.query.helpDocument;
      adminManager
        .form("design", null, { helpDocument: this.helpDocument })
        .then((data) => {
          if (!this.helpDocument) {
            this.tabs = data.tabs;
          } else {
            this.models = data;
          }
          this.loading = false;
        });
    },

    cancel() {
      this.$router.push({
        name: "adminList",
        params: { alias: "help-document" },
      });
    },

    submit() {
      let response = [];
      this.loading = true;
      this.invalidTab = null;

      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response.push({
            form: tab.models ? formManager.processForm(tab.models, false) : [],
            code: tab.code,
          });
        }
      });

      const urlCall = "/app/admin/design/";
      apiConnection.put(urlCall, response).then((data) => {
        if (data && !data.hasError) {
          this.$router.push({
            name: "adminDesign",
            query: { time: Date.now() },
          });
          notificationManager.showNotification(
            "success",
            this.$tc("global.edit.success", 1, {
              name: this.$t("admin.design.title"),
            }),
          );
        } else if (data.responses && data.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          Object.entries(this.tabs).forEach((tab) => {
            const errors = formValidator.processResponses(
              data.responses[0].validationErrors,
              tab[1].models,
            );
            if (errors.length) {
              this.invalidTab = this.id ? tab[1] : tab[0];
            }
          });
        }
        this.$store.dispatch("fetchDesign", { disabledLoading: true });
        this.init();
        this.loading = false;
      });
    },

    submitModels() {
      this.loading = true;
      const result = formManager.processForm(this.models);
      const urlCall = "/app/admin/generic/design/unique-post";
      apiConnection.put(urlCall, result).then((data) => {
        const message = this.$t("admin.help_settings_success");
        const route = {
          name: "adminList",
          params: { alias: "help-document" },
          query: { time: Date.now() },
        };
        formManager.processResponse(data, route, message, this.models);
        this.loading = false;
      });
    },

    validTab() {
      this.invalidTab = null;
    },
  },
  components: {
    FormBlockComponent,
    SubmitTypeComponent,
    FormTabsBlockComponent,
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
