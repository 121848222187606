<template>
  <h1 :class="{ start: !activeWebsite.name }">
    <span class="fw-normal fs-6">{{
      activeWebsite.name ? $t("loginTo") : $t("loginMobile")
    }}</span
    ><br />
    <span class="brand-name textStyle">{{ activeWebsite.name }}</span>
  </h1>
  <div v-if="message" class="badge badge-danger me-1 w-100">
    {{ message }}
  </div>
  <form class="text-start">
    <div class="form">
      <div id="username-field" class="field-wrapper input">
        <svg
          class="feather feather-user"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <input
          v-model="login"
          :placeholder="$t('username')"
          class="form-control"
          type="text"
          @input="reset"
        />
        <div v-if="loginError" class="invalid-feedback d-block">
          {{ loginError }}
        </div>
      </div>
      <div v-if="Object.keys(choices).length > 1" class="field-wrapper input">
        <svg
          class="feather feather-globe"
          data-v-5522efca=""
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="2" x2="22" y1="12" y2="12"></line>
          <path
            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
          ></path>
        </svg>
        <select class="form-select" @change="select($event)">
          <option
            v-for="platform in choices"
            :key="platform"
            :value="platform.value"
          >
            {{ platform.label }}
          </option>
        </select>
      </div>

      <div
        v-if="selectedPlatform"
        id="password-field"
        class="field-wrapper input mb-2"
      >
        <svg
          class="feather feather-lock"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>
        <input
          v-if="showPassword"
          v-model="password"
          :placeholder="$t('password')"
          class="form-control"
          type="text"
          @input="checkInput('password')"
        />
        <input
          v-else
          v-model="password"
          :placeholder="$t('password')"
          class="form-control"
          type="password"
          @input="checkInput('password')"
        />
        <div v-if="passwordError" class="invalid-feedback d-block">
          {{ passwordError }}
        </div>
      </div>
      <div class="d-sm-flex justify-content-between">
        <div
          v-if="selectedPlatform"
          class="field-wrapper toggle-pass d-flex align-items-center"
        >
          <p class="d-inline-block">{{ $t("showPassword") }}</p>
          <label class="switch s-primary mx-2">
            <input
              v-model="showPassword"
              class="custom-control-input"
              type="checkbox"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="field-wrapper text-center mt-3">
          <button
            class="btn btn-primary blockStyle"
            type="submit"
            @click="next($event)"
          >
            <span
              v-if="this.loading"
              class="spinner-border loader-sm me-2"
            ></span>
            <span v-if="!this.loading">{{
              this.selectedPlatform ? $t("login") : $t("confirm")
            }}</span>
            <span v-else>{{ $t("loading") }}</span>
          </button>
        </div>
      </div>

      <div class="field-wrapper" v-if="selectedPlatform">
        <router-link class="forgot-pass-link" to="/auth/pass-recovery"
          >{{ $t("forgotPasswordLink") }}
        </router-link>
      </div>
    </div>
  </form>
  <div
    :class="{ 'form-footer': true, abs: Object.keys(choices).length <= 1 }"
  ></div>
</template>

<script>
import { mapState } from "vuex";
import { apiConnection } from "@/services/api-connection";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
import { colorManager } from "@/services/platform/color-manager";

export default {
  name: "MobileLoginView",
  components: {},
  data() {
    return {
      message: "",
      choices: [],
      selectedPlatform: "",
      login: "",
      loginError: "",
      password: "",
      passwordError: "",
      loading: false,
      showPassword: false,
    };
  },
  computed: {
    ...mapState({
      websites: (state) => state.platform.websiteList,
      activeWebsite: (state) => state.platform.design,
      logo: (state) => state.platform.logo,
      user: (state) => state.user.user,
      trainingCourses: (state) => state.trainingCourse.trainingCourseList,
    }),
  },
  created() {
    const $this = this;
    setTimeout(function () {
      $this.$store.commit("SET_APP_LOADING", false);
    }, 2000);
  },
  methods: {
    reset() {
      this.choices = [];
      this.website = "";
      this.$store.commit("SET_WEBSITE_LIST", {});
      this.password = "";
      this.checkInput("login");
    },

    checkInput(input) {
      if (!this.$data[`${input}`]) {
        this.$data[`${input}Error`] = this.$t("requiredField");
      } else {
        this.$data[`${input}Error`] = "";
      }
    },

    next(e) {
      e.preventDefault();
      this.message = "";

      if (!this.login) {
        this.loginError = this.$t("requiredField");
      }

      this.loading = true;
      if (!Object.keys(this.choices).length && this.login) {
        this.$store.dispatch("fetchWebsiteFromUser", this.login).then(() => {
          let choices = [];
          if (Object.keys(this.websites).length) {
            this.websites.forEach(function (website) {
              choices.push({ label: website.name, value: website.id });
            });
            this.choices = choices;
            this.website = choices[0];
            const website = this.websites.find(
              (item) => item.id === this.website.value,
            );
            this.$store.commit("SET_WEBSITE", website);
            colorManager.setPlatformStyle(website);
          } else {
            this.message = this.$t("badCredentials");
          }
          this.loading = false;
        });
      } else if (this.website && this.password) {
        const website = this.websites.find(
          (item) => item.id === this.website.value,
        );
        this.$store.commit("SET_WEBSITE", website);
        apiConnection.login(this.login, this.password).then((response) => {
          if (!response.success) {
            this.message = this.$t("badCredentials");
          } else {
            this.$store.commit("SET_APP_LOADING", true);
            window.localStorage.setItem("studea-stayConnected", 1);
            this.message = "";
            this.$store.dispatch("fetchUserInfos").then(() => {
              this.$store.dispatch("fetchTrainingCourseList", {}).then(() => {
                if (this.user.roles.includes("ROLE_STUDEA_ADMIN")) {
                  this.$store.commit("SET_APP_LOADING", false);
                  window.localStorage.setItem(
                    "studea-returnPathName",
                    JSON.stringify({ name: "TrainingCourseList" }),
                  );
                  this.$router.push({ name: "adminHome" });
                } else if (Object.keys(this.trainingCourses).length > 1) {
                  this.$store.commit("SET_APP_LOADING", false);
                  this.$router.push({ name: "TrainingCourseList" });
                } else {
                  this.$store.commit("SET_APP_LOADING", false);
                  this.$router.push({ name: "Dashboard" });
                }
              });
            });
          }
          this.loading = false;
        });
      } else if (!this.password && this.login) {
        this.passwordError = this.$t("requiredField");
      }
    },

    select(e) {
      const website = this.websites.find(
        (item) => item.id === parseInt(e.target.value),
      );
      this.$store.commit("SET_WEBSITE", website);
      colorManager.setPlatformStyle(website);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-footer {
  margin-bottom: 20px;

  &.abs {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-bottom: 0;
  }
}
</style>
