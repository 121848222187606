import { i18n } from "@/i18n";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import platform from "@/store/platform/platform";

const state = {
  questionnaire: {},
  lastSavedVersion: {},
  lastIndex: 0,
  questionnaires: {},
};

const mutations = {
  INIT_LAST_INDEX(state, index) {
    state.lastIndex = index;
  },

  RETRIEVE_NEW(state) {
    if (window.localStorage.getItem("studea-questionnaireEditor")) {
      state.questionnaire = JSON.parse(
        window.localStorage.getItem("studea-questionnaireEditor"),
      );
    }
  },

  QUESTIONNAIRE_NEW(state) {
    state.questionnaire = {
      type: "",
      title: "",
      active: true,
      locked: false,
      useCrewDates: false,
      useInterviewDate: false,
      interviewDateLabel: "Date de visite en entreprise",
      interviewDateVisibleBy: null,
      defaultAllAccessDate: true,
      defaultAllAccessStartDate: "",
      defaultAllAccessEndDate: "",
      defaultApprenticeAccessStartDate: "",
      defaultApprenticeAccessEndDate: "",
      defaultTutorAccessStartDate: "",
      defaultTutorAccessEndDate: "",
      defaultApprenticeMasterAccessStartDate: "",
      defaultApprenticeMasterAccessEndDate: "",
      defaultStudeaManagerAccessStartDate: "",
      defaultStudeaManagerAccessEndDate: "",
      completenessDate: "",
      firstReminder: "",
      secondReminder: "",
      code: "",
      secondaryQuestionnaires: [],
      description: "",
      descriptionPdf: null,
      visibleBy: [
        {
          label: platform.state.apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: platform.state.tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: platform.state.apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
      ],
      signedBy: [
        {
          label: platform.state.apprenticeProfileLabel.title,
          value: 1,
          data: 1,
          checked: false,
          disabled: true,
        },
        {
          label: platform.state.tutorProfileLabel.title,
          value: 2,
          data: 2,
          checked: false,
          disabled: true,
        },
        {
          label: platform.state.apprenticeMasterProfileLabel.title,
          value: 3,
          data: 3,
          checked: false,
          disabled: true,
        },
        {
          label: "Chargé de livret",
          value: 4,
          data: 4,
          checked: false,
          disabled: true,
        },
      ],
      questions: [],
      category: null,
      trainingCourse: null,
      markAutomatic: false,
      canBeCopiedBy: [],
      marksVisibleBy: [
        {
          label: i18n.global.t("Apprentice"),
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: i18n.global.t("Tutor"),
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: i18n.global.t("Apprentice master"),
          value: "3",
          data: 3,
          checked: false,
        },
      ],
      markMaximum: "",
      summarySheet: false,
      allProfilSummarySheet: false,
      template: null,
    };
  },

  QUESTIONNAIRE_ADD(state, type) {
    state.lastIndex++;

    const signedBy = state.questionnaire.signedBy
      ? state.questionnaire.signedBy.filter((item) => item.checked)
      : [
          {
            label: platform.state.apprenticeProfileLabel.title,
            value: "1",
            data: 1,
            checked: false,
          },
          {
            label: platform.state.tutorProfileLabel.title,
            value: "2",
            data: 2,
            checked: false,
          },
          {
            label: platform.state.apprenticeMasterProfileLabel.title,
            value: "3",
            data: 3,
            checked: false,
          },
        ];
    let access = [];
    if (signedBy.length === 1) {
      access = [signedBy[0].value];
    }

    const item = {
      id: null,
      index: state.lastIndex,
      title: "",
      type: 0,
      itemType: "field",
      access: access,
      tip: "",
      tipActive: false,
      required: false,
      selected: false,
      displayMode: 1,
      comments: false,
      commentsLabel: "",
      commentsType: 1,
      displayMark: false,
      displayRadar: false,
      maximalMark: "",
      openedQuestionsAccess: [],
      openedQuestions: false,
      openedQuestionsType: 1,
      fieldSkills: [],
    };

    switch (type) {
      case 2:
        item["itemType"] = "cluster";
        item["displayMode"] = 1;
        break;
      case 3:
        item["itemType"] = "cluster";
        item["grid"] = true;
        item["valueList"] = [
          { value: "", mark: "" },
          { value: "", mark: "" },
        ];
        item["questions"] = [
          {
            id: null,
            index: 2,
            title: "",
            access: access,
            tip: "",
            tipActive: false,
            required: 0,
            selected: true,
            comments: false,
            commentsLabel: "",
            commentsType: 1,
            displayMark: false,
            displayRadar: false,
            maximalMark: "",
            openedQuestionsAccess: [],
            openedQuestions: false,
            openedQuestionsType: 0,
            fieldSkills: [],
          },
          {
            id: null,
            index: 3,
            title: "",
            access: access,
            tip: "",
            tipActive: false,
            required: 0,
            selected: true,
            comments: false,
            commentsLabel: "",
            commentsType: 1,
            displayMark: false,
            displayRadar: false,
            maximalMark: "",
            openedQuestionsAccess: [],
            openedQuestions: false,
            openedQuestionsType: 0,
            fieldSkills: [],
          },
        ];
        item["displayMode"] = 2;
        break;
    }

    state.questionnaire["questions"].push(item);
    window.localStorage.setItem(
      "studea-questionnaireEditor",
      JSON.stringify(state.questionnaire),
    );
  },

  QUESTIONNAIRE_DUPLICATE(state, payload) {
    state.lastIndex++;
    const item = getters.findQuestionnaireItem(
      state.questionnaire.questions,
      payload.id,
    );
    const clone = Object.assign({}, item);
    clone.index = state.lastIndex;
    clone.id = null;

    if (item.questions && Object.keys(item.questions).length) {
      clone.questions = [];
      Object.values(item.questions).forEach(function (child) {
        const cloneQuestion = Object.assign({}, child);
        cloneQuestion.id = null;
        clone.questions.push(cloneQuestion);
      });
    }

    if (item.valueList && Object.keys(item.valueList).length) {
      clone.valueList = [];
      Object.values(item.valueList).forEach(function (value) {
        clone.valueList.push({ mark: value.mark, value: value.value });

        if (clone.questions && Object.keys(clone.questions).length) {
          Object.values(clone.questions).forEach(function (child) {
            child.valueList = clone.valueList;
          });
        }
      });
    }
    state.questionnaire.questions.splice(payload.index + 1, 0, clone);
    window.localStorage.setItem(
      "studea-questionnaireEditor",
      JSON.stringify(state.questionnaire),
    );
  },

  QUESTIONNAIRE_REMOVE(state, index) {
    const item = state.questionnaire.questions[index];
    if (item.id) {
      state.questionnaire["deletedQuestions"] = state.questionnaire[
        "deletedQuestions"
      ]
        ? state.questionnaire["deletedQuestions"]
        : [];
      state.questionnaire["deletedQuestions"].push({
        itemType: item.itemType,
        id: item.id,
      });
    }
    state.questionnaire.questions.splice(index, 1);
    window.localStorage.setItem(
      "studea-questionnaireEditor",
      JSON.stringify(state.questionnaire),
    );
  },

  QUESTIONNAIRE_SELECT_BLOCK(state, index = null) {
    if (
      state.questionnaire &&
      state.questionnaire.questions &&
      Object.keys(state.questionnaire.questions).length
    ) {
      Object.values(state.questionnaire.questions).forEach(function (baseItem) {
        baseItem.selected = false;
        if (baseItem.questions && Object.keys(baseItem.questions).length) {
          Object.values(baseItem.questions).forEach(function (baseChild) {
            baseChild.selected = false;
          });
        }
      });

      const item = getters.findQuestionnaireItem(
        state.questionnaire.questions,
        index,
      );
      if (item) {
        item.selected = true;

        if (item.questions && Object.keys(item.questions).length) {
          Object.values(item.questions).forEach(function (child) {
            child.selected = true;
          });
        }
      }
    }
  },

  QUESTIONNAIRE_UPDATE(state, payload) {
    state.questionnaire = payload.questionnaire;
    if (payload.save) {
      window.localStorage.setItem(
        "studea-questionnaireEditor",
        JSON.stringify(state.questionnaire),
      );
    }
  },

  QUESTIONNAIRE_SET_LAST_SAVED_VERSION(state, questionnaire) {
    state.lastSavedVersion = JSON.stringify(questionnaire);
  },

  INIT_QUESTIONNAIRE_LIST(state, list) {
    state.questionnaires = list;
  },

  INIT_QUESTIONNAIRE_CHOICES(state) {
    const signedBy = [
      {
        label: platform.state.apprenticeProfileLabel.title,
        value: "1",
        data: 1,
        checked: false,
      },
      {
        label: platform.state.tutorProfileLabel.title,
        value: "2",
        data: 2,
        checked: false,
      },
      {
        label: platform.state.apprenticeMasterProfileLabel.title,
        value: "3",
        data: 3,
        checked: false,
      },
      {
        label: i18n.global.t("Studea manager"),
        value: "4",
        data: 4,
        checked: false,
      },
    ];
    signedBy.forEach((choice) => {
      choice.checked = state.questionnaire.signedBy.includes(choice.data);
    });
    state.questionnaire.signedBy = signedBy;

    const marksVisibleBy = [
      {
        label: platform.state.apprenticeProfileLabel.title,
        value: "1",
        data: 1,
        checked: false,
      },
      {
        label: platform.state.tutorProfileLabel.title,
        value: "2",
        data: 2,
        checked: false,
      },
      {
        label: platform.state.apprenticeMasterProfileLabel.title,
        value: "3",
        data: 3,
        checked: false,
      },
    ];
    marksVisibleBy.forEach((choice) => {
      choice.checked = state.questionnaire.marksVisibleBy.includes(choice.data);
    });
    state.questionnaire.marksVisibleBy = marksVisibleBy;

    const canBeCopiedBy = [
      {
        label: platform.state.apprenticeProfileLabel.title,
        value: "1",
        data: 1,
        checked: false,
      },
      {
        label: platform.state.tutorProfileLabel.title,
        value: "2",
        data: 2,
        checked: false,
      },
      {
        label: platform.state.apprenticeMasterProfileLabel.title,
        value: "3",
        data: 3,
        checked: false,
      },
    ];
    canBeCopiedBy.forEach((choice) => {
      choice.checked = state.questionnaire.canBeCopiedBy.includes(choice.data);
    });
    state.questionnaire.canBeCopiedBy = canBeCopiedBy;

    const visibleBy = [
      {
        label: platform.state.apprenticeProfileLabel.title,
        value: "1",
        data: 1,
        checked: false,
      },
      {
        label: platform.state.tutorProfileLabel.title,
        value: "2",
        data: 2,
        checked: false,
      },
      {
        label: platform.state.apprenticeMasterProfileLabel.title,
        value: "3",
        data: 3,
        checked: false,
      },
    ];
    visibleBy.forEach((choice) => {
      choice.checked = state.questionnaire.visibleBy.includes(choice.data);
    });
    state.questionnaire.visibleBy = visibleBy;
  },
};

const getters = {
  findQuestionnaireItem(list, index) {
    return Object.values(list).find((item) => item.index === index);
  },
};

const actions = {
  fetchEditorQuestionnaire({ commit }, payload) {
    return questionnaireManager
      .getAdminQuestionnaire(payload.id, payload.trainingCourseId)
      .then((questionnaire) => {
        if (questionnaire) {
          commit("QUESTIONNAIRE_UPDATE", {
            questionnaire: questionnaire,
            save: false,
          });
          commit("QUESTIONNAIRE_SET_LAST_SAVED_VERSION", questionnaire);
        }
      });
  },

  fetchQuestionnaireList({ commit }, trainingCourseId) {
    return questionnaireManager
      .getAdminQuestionnaireList(trainingCourseId)
      .then((list) => {
        commit("INIT_QUESTIONNAIRE_LIST", list);
      });
  },

  saveQuestionnaire({ state }, payload) {
    const questionnaire = questionnaireManager.processQuestionnaire(
      state.questionnaire,
    );
    return new Promise((resolve, reject) => {
      questionnaireManager
        .postAdminQuestionnaire(
          questionnaire,
          payload.trainingCourseId,
          payload.id,
        )
        .then((res) => {
          if (!res.hasError) {
            resolve(res);
            notificationManager.showNotification(
              "success",
              i18n.global.t("studea_manager.questionnaire.save_success"),
            );
          } else {
            reject(res);
            notificationManager.showNotification(
              "success",
              i18n.global.t("studea_manager.questionnaire.save_error"),
            );
          }
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
