import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { stringManager } from "@/services/utilities/string-manager";
import { fileManager } from "@/services/file/file-manager";
import store from "@/store";
import router from "@/router";

const URL_MENU = "/app/studea-manager/menu/";

class StudeaManagerManager {
  getMenu(id) {
    return apiConnection
      .get(URL_MENU + id, [], true)
      .then((response) => response);
  }

  remove(
    alias,
    trainingCourseId = null,
    id = null,
    tradGender = 1,
    generic = true,
  ) {
    const url =
      "/app/studea-manager" +
      (trainingCourseId ? "/" + trainingCourseId : "") +
      (generic ? "/generic" : "") +
      "/" +
      alias +
      "/delete/" +
      id;

    const tradPrefix = stringManager.toCamelCase(alias);

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", tradGender, {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", tradGender, {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.delete(url, {}).then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.tc("global.delete.success", tradGender, {
                      name: i18n.global.tc(
                        "studea_manager." + tradPrefix + ".title",
                        1,
                      ),
                    }),
              );
            });
          }
        });
    });
  }

  removeTriadMember(trainingCourseId, id, profile, userId) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/triad/delete-member/" +
      id +
      "/" +
      userId +
      "/" +
      profile;

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", 1, {
            name: i18n.global
              .tc("studea_manager.triad.delete_member.title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", 1, {
            name: i18n.global
              .tc("studea_manager.triad.delete_member.title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.delete(url, {}).then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.tc("global.delete.success", 1, {
                      name: i18n.global.tc("studea_manager.triad.title", 1),
                    }),
              );
            });
          }
        });
    });
  }

  getQuestionnaireConfig(trainingCourseId, id = null) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/questionnaire/form" +
          (id ? "/" + id : "/add"),
        [],
        true,
      )
      .then((response) => response);
  }

  sort(alias, trainingCourseId, id, position, generic = true, type = null) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/" +
      (generic ? "generic/" : "") +
      alias +
      "/sort/" +
      id +
      "/" +
      position;
    const tradPrefix = stringManager.toCamelCase(alias);
    let params = {};
    if (type) {
      params["type"] = type;
    }

    return apiConnection.put(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.t("global.sort.success", {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 2)
              .toLowerCase(),
          }),
        );
      }
    });
  }

  copy(
    alias,
    trainingCourseId,
    id,
    tradGender = 1,
    generic = true,
    params = {},
  ) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      (generic ? "/generic/" : "/") +
      alias +
      "/copy/" +
      id;
    const tradPrefix = stringManager.toCamelCase(alias);
    return apiConnection.post(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.tc("global.copy.success", tradGender, {
            name: i18n.global.tc("studea_manager." + tradPrefix + ".title", 2),
          }),
        );
      }
    });
  }

  editModule(trainingCourseId, id, params = {}) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/training-course-module-triad/edit/" +
      id;
    return apiConnection.put(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.t(
            "studea_manager.module." +
              (params.visible ? "active" : "unactive") +
              ".success",
          ),
        );
        store.commit("SET_MODULE_VISIBILITY", {
          id: id,
          visible: params.visible,
        });
      } else {
        notificationManager.showNotification(
          "error",
          i18n.global.t("signed_error"),
        );
      }
      store.dispatch("fetchStudeaManagerMenu", {
        trainingCourseId: trainingCourseId,
        refresh: false,
      });
    });
  }

  move(alias, trainingCourseId, id, dest = null, tradGender = 1, params = {}) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/" +
      (alias === "category" ? "questionnaire/category/" : alias + "/") +
      "move/" +
      (dest ? dest + "/" : "") +
      id;
    const tradPrefix = stringManager.toCamelCase(alias);
    return apiConnection.put(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.tc("global.move.success", tradGender, {
            name: i18n.global.tc("studea_manager." + tradPrefix + ".title", 2),
          }),
        );
      }
    });
  }

  list(
    alias,
    trainingCourseId = null,
    generic = false,
    params = {},
    triadId = null,
  ) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          (trainingCourseId ? trainingCourseId + "/" : "") +
          (generic ? "generic/" : "") +
          encodeURI(alias) +
          "/list" +
          (triadId ? "/" + triadId : ""),
        params,
      )
      .then((response) => response);
  }

  post(alias, trainingCoursId, generic = false, params = {}, triadId = null) {
    const url =
      "/app/studea-manager/" +
      trainingCoursId +
      "/" +
      (generic ? "generic/" : "/") +
      encodeURI(alias) +
      "/post" +
      (triadId ? "/" + triadId : "");
    return apiConnection.post(url, params);
  }

  test(alias, trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/" +
          encodeURI(alias) +
          "/generate-triad-test",
      )
      .then((response) => response);
  }

  form(
    alias,
    trainingCourseId = null,
    id = null,
    params = {},
    generic = false,
    search = false,
    unique = false,
  ) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          (trainingCourseId ? trainingCourseId + "/" : "") +
          (generic ? "generic/" : "") +
          encodeURI(alias) +
          (search ? "/search" : "") +
          "/" +
          (unique ? "unique-" : "") +
          "form" +
          (id ? "/" + id : ""),
        params,
      )
      .then((response) => response);
  }

  importForm(alias, trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/" +
          encodeURI(alias) +
          "/import-form",
      )
      .then((response) => response);
  }

  sendWelcome(data, trainingCourseId) {
    return apiConnection
      .post("/app/studea-manager/" + trainingCourseId + "/triad/send-welcome", {
        users: data,
      })
      .then((response) => {
        if (response) {
          notificationManager.showNotification(
            "success",
            i18n.global.t("studea_manager.triad.send_welcome_email"),
          );
        }
      });
  }

  sendNewPassword(data, trainingCourseId) {
    return apiConnection
      .post(
        "/app/studea-manager/" + trainingCourseId + "/triad/send-new-password",
        {
          users: data,
        },
      )
      .then((response) => {
        if (response) {
          notificationManager.showNotification(
            "success",
            i18n.global.t("studea_manager.triad.send_welcome_email"),
          );
        }
      });
  }

  export(alias, trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/" +
          encodeURI(alias) +
          "/export",
      )
      .then((response) => {
        if (response && response.data) {
          fileManager.saveFile(response.filename, response.data, response.type);
        } else {
          notificationManager.showNotification(
            "error",
            i18n.global.t("signed_error"),
          );
        }
      });
  }

  isStudeaManager(id) {
    const list = store.state.trainingCourse.trainingCourseList;
    if (Object.keys(list).length) {
      const trainingCourse = list.find((item) => item.id === parseInt(id));
      if (trainingCourse && trainingCourse.isStudeaManager) {
        return true;
      }
    }
    return false;
  }

  switchUser(id) {
    return apiConnection.get("/app/user/switch/" + id).then((response) => {
      if (response.token && response.refreshToken) {
        const initialToken =
          window.localStorage.getItem("studea-token") ||
          window.sessionStorage.getItem("studea-token");
        const initialRefreshToken =
          window.localStorage.getItem("studea-refreshToken") ||
          window.sessionStorage.getItem("studea-refreshToken");

        window.localStorage.setItem("initial-studea-token", initialToken);
        window.localStorage.setItem(
          "initial-studea-refreshToken",
          initialRefreshToken,
        );
        window.sessionStorage.setItem("initial-studea-token", initialToken);
        window.sessionStorage.setItem(
          "initial-studea-refreshToken",
          initialRefreshToken,
        );

        window.sessionStorage.setItem("studea-switched", 1);
        window.sessionStorage.setItem("studea-switchedPopup", 1);

        window.localStorage.removeItem("studea-token");
        window.localStorage.removeItem("studea-refreshToken");
        window.sessionStorage.removeItem("studea-token");
        window.sessionStorage.removeItem("studea-refreshToken");

        router.push({
          name: "loginSwitch",
          query: { token: response.token, refreshToken: response.refreshToken },
        });
      }
    });
  }

  restoreUser() {
    const initialToken =
      window.localStorage.getItem("initial-studea-token") ||
      window.sessionStorage.getItem("initial-studea-token");
    const initialRefreshToken =
      window.localStorage.getItem("initial-studea-refreshToken") ||
      window.sessionStorage.getItem("initial-studea-refreshToken");
    if (initialToken && initialRefreshToken) {
      window.localStorage.setItem("studea-token", initialToken);
      window.localStorage.setItem("studea-refreshToken", initialRefreshToken);
      window.sessionStorage.setItem("studea-token", initialToken);
      window.sessionStorage.setItem("studea-refreshToken", initialRefreshToken);

      window.localStorage.removeItem("initial-studea-token");
      window.localStorage.removeItem("initial-studea-refreshToken");
      window.sessionStorage.removeItem("initial-studea-token");
      window.sessionStorage.removeItem("initial-studea-refreshToken");
      window.sessionStorage.removeItem("studea-switched");
      window.sessionStorage.removeItem("studea-switchedPopup");

      // Rediriger vers la page courante
      router.go(0);
    } else {
      console.error(
        "Jetons initiaux non trouvés. Impossible de restaurer l'utilisateur.",
      );
    }
  }

  toggle(item, name, isMultipleElement) {
    const itemSearch = "." + name + item;
    if (isMultipleElement) {
      document.querySelectorAll(itemSearch).forEach((row) => {
        row.classList.toggle("on");
      });
    } else {
      document.querySelector(itemSearch).classList.toggle("on");
    }
  }

  unlockReply(profile, id, triad) {
    const url = "/app/questionnaire/unlock/" + triad + "/" + id;
    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlock_questionnaire",
          ),
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlocked_confirmation",
          ),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection.get(url, { profile: profile }).then((res) => {
              if (res) {
                resolve(true);
                notificationManager.showNotification(
                  "success",
                  i18n.global.t(
                    "studea_manager.dashboard.questionnaire.success_unlock",
                  ),
                );
              }
            });
          }
        });
    });
  }

  unlockAllReplies(id, trainingCourseId) {
    return new Promise((resolve) => {
      const url =
        "/app/studea-manager/" + trainingCourseId + "/unlock-all/" + id;
      notificationManager
        .showAlert(
          "error",
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlock_all_questionnaires",
          ),
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlocked_confirmation",
          ),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection.get(url, {}).then((res) => {
              if (res) {
                resolve(true);
                notificationManager.showNotification(
                  "success",
                  i18n.global.t(
                    "studea_manager.dashboard.questionnaire.success_all_unlock",
                  ),
                );
              }
            });
          }
        });
    });
  }

  exportToExcel(url) {
    //use download function
    console.log("Exporter le dashboard en Excel depuis : " + url);
  }

  getDashboardData(trainingCourse, params) {
    return apiConnection.get(
      "/app/studea-manager/" + trainingCourse + "/dashboard",
      params,
    );
  }
}

export const studeaManagerManager = new StudeaManagerManager();
