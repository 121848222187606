<template>
  <div class="widget widget-summary-1">
    <div class="widget-heading">
      <h5>{{ $t("dashboard.indicator") }}</h5>
    </div>
    <div class="widget-content">
      <div v-for="item in datas" :key="item.label" class="elem">
        <div v-if="item.type === 'progress'">
          <div class="d-flex mb-1 justify-content-between align-items-center">
            <span class="textStyle">{{ item.label }}</span>
            <button class="btn-icon iconStyle">
              <FontAwesomeIcon icon="fa-light fa-eye" />
            </button>
          </div>
          <div class="progress br-30">
            <span
              :class="
                'progress__text' + (parseInt(item.value) > 50 ? ' -fifty' : '')
              "
              >{{ item.value }}</span
            >
            <div
              :aria-valuenow="parseInt(item.value)"
              :style="'width:' + parseInt(item.value) + '%'"
              aria-valuemax="100"
              aria-valuemin="0"
              class="progress-bar bg-gradient-success progressBarStyle"
              role="progressbar"
            ></div>
          </div>
        </div>
        <div v-else>
          <div class="blockStyle summary-list summary-profit mt-3">
            <div class="summery-info">
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6 class="textStyle">
                    {{ item.label }}
                    <span class="summary-count">{{ item.total }}</span>
                  </h6>
                  <button class="btn-icon -white">
                    <FontAwesomeIcon icon="fa-light fa-eye" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "IndicatorBlock",
  methods: {},
  components: { FontAwesomeIcon },
  data() {
    return {
      datas: [
        {
          label: "Taux de remplissage questionnaires",
          value: "45%",
          total: 132,
          type: "progress",
        },
        {
          label: "Taux de remplissage compétences",
          value: "32%",
          total: 253,
          type: "progress",
        },
        {
          label: "Taux de visites réalisées",
          value: "18%",
          total: 28,
          type: "progress",
        },
        {
          label: "Taux de connexion utilisateurs",
          value: "64%",
          total: 355,
          type: "progress",
        },
        {
          label: "Taux de trinômes incomplets",
          value: "12%",
          total: 4,
          type: "progress",
        },
        {
          label: "Nombre de fiches incident créées",
          total: 37,
          type: "button",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h6,
.summary-average,
.summary-count {
  color: $white !important;
}

.progress {
  position: relative;
  margin-bottom: 0;

  &__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;

    &.-fifty {
      color: $white;
    }
  }
}

.elem + .elem {
  margin-top: 15px;
}
</style>
