<template>
  <div class="position-relative">
    <QuestionnaireHeadingComponent
      :formTitle="questionnaire.title"
      :front="true"
      :openingDate="openingDate"
      :pdfSrc="pdfSrc"
    />
    <div id="questionnaire" class="questionnaire">
      <QuestionnaireQuestionComponent
        v-if="interviewDateEnable()"
        :front="true"
        :index="50000"
        :item="interviewDate"
        :model="model"
        :notSortable="true"
        @draft="saveDraft"
        @update="update"
        @focused="focused"
      />
      <div
        v-for="(item, key) in questionnaire.questions"
        :key="item"
        class="block"
      >
        <div v-if="item.itemType === 'cluster'">
          <QuestionnaireClusterComponent
            v-if="triad && triad.id"
            :front="true"
            :index="key"
            :item="item"
            :otherReplies="otherReplies"
            @draft="saveDraft"
            @update="update"
            @focused="focused"
          />
        </div>

        <div v-else>
          <QuestionnaireQuestionComponent
            v-if="triad && triad.id"
            :front="true"
            :index="key"
            :item="item"
            :model="model"
            @draft="saveDraft"
            @update="update"
            @focused="focused"
            @isLoading="setLoading"
          />
          <QuestionnaireCommentComponent
            v-if="
              item.comments &&
              questionnaire.useComments &&
              item.userAnswer &&
              item.userAnswer.arrayValue.answerComments
            "
            :comments="item.userAnswer.arrayValue.answerComments"
          />
          <div
            v-for="answer in item.fieldAnswers"
            :key="answer"
            class="answer-container"
          >
            <QuestionnaireAnswerComponent
              :answer="answer"
              :item="item"
              @draft="saveDraft"
              @commentAdded="commentAdded"
              @isLoading="setLoading"
            />
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { fileManager } from "@/services/file/file-manager";
import QuestionnaireHeadingComponent from "@/components/questionnaire/editor/QuestionnaireHeadingComponent.vue";
import QuestionnaireClusterComponent from "@/components/questionnaire/editor/QuestionnaireClusterComponent.vue";
import QuestionnaireQuestionComponent from "@/components/questionnaire/editor/QuestionnaireQuestionComponent.vue";
import QuestionnaireCommentComponent from "@/components/questionnaire/QuestionnaireCommentComponent.vue";
import QuestionnaireAnswerComponent from "@/components/questionnaire/QuestionnaireAnswerComponent.vue";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { userManager } from "@/services/security/user-manager";

export default {
  name: "QuestionnaireBlockComponent",
  components: {
    QuestionnaireAnswerComponent,
    QuestionnaireCommentComponent,
    QuestionnaireQuestionComponent,
    QuestionnaireClusterComponent,
    QuestionnaireHeadingComponent,
    BlockLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    pdfSrc: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      documentListenerInitialized: false,
      openingDate: "",
      interviewDate: null,
      inputFocused: null,
      otherReplies: [],
    };
  },
  watch: {
    loading() {
      if (!this.loading && !this.documentListenerInitialized) {
        this.documentListenerInitialized = true;
      }
    },
  },
  updated() {
    fileManager.initDocumentListener();
  },
  mounted() {
    this.setOpeningDate();
    this.setOtherReplies();
    fileManager.initDocumentListener();
  },
  computed: {
    ...mapState({
      questionnaire: (state) => state.questionnaire.questionnaire,
      model: (state) => state.questionnaire.model,
      user: (state) => state.user.user,
      triad: (state) => state.triad.triad,
      loading: (state) => state.questionnaire.questionnaireLoading,
    }),
  },
  methods: {
    update() {
      this.$emit("update");
    },

    setOtherReplies() {
      if (this.questionnaire.lastReply && this.triad.userRoles) {
        const current = userManager.getProfileKey(this.triad.userRoles[0]);
        Object.entries(this.questionnaire.lastReply).forEach((entry) => {
          if (entry[0] !== current && entry[1]) {
            this.otherReplies.push(entry[1]);
          }
        });
      }
    },

    saveDraft() {
      this.$emit("isLoading", true);
      questionnaireManager
        .saveQuestionnaire(1, this.$t("draftSuccess"), this.$t("signed_error"))
        .then(() => {
          if (this.inputFocused) {
            const input = document.getElementById(this.inputFocused);
            if (input) {
              input.focus();
            }
          }
          this.$emit("isLoading", false);
        });
    },

    commentAdded(payload) {
      payload.answer.answerComments.push(payload.comment);
    },

    focused(id) {
      this.inputFocused = id;
    },

    setLoading(loading) {
      this.$emit("isLoading", loading);
    },

    interviewDateEnable() {
      const userReplyProfile = this.questionnaire.userReply
        ? this.questionnaire.userReply.writerProfile
        : null;
      let access =
        userReplyProfile &&
        this.questionnaire.interviewDateVisibleBy === userReplyProfile;
      if (
        !access &&
        this.triad &&
        this.triad.userRoles &&
        this.questionnaire.interviewDateVisibleBy
      ) {
        access =
          this.triad.userRoles[0] === this.questionnaire.interviewDateVisibleBy;
      }

      const enable =
        this.questionnaire && this.questionnaire.useInterviewDate && access;

      if (enable) {
        this.setInterviewDate();
      }

      return enable;
    },

    setInterviewDate() {
      this.interviewDate = {
        id: "interviewDate",
        index: 50000,
        title: this.questionnaire ? this.questionnaire.interviewDateLabel : "",
        type: 11,
        itemType: "field",
        access: [this.questionnaire.interviewDateVisibleBy],
        tip: "",
        tipActive: false,
        required: 1,
        selected: false,
        displayMode: 1,
        comments: false,
        commentsLabel: "",
        commentsType: 1,
        displayMark: false,
        displayRadar: false,
        maximalMark: "",
        openedQuestionsAccess: [],
        openedQuestions: false,
        openedQuestionsType: 1,
        enabled: true,
      };

      const reply = this.questionnaire.userReply;

      this.model["interviewDate"] = questionnaireManager.buildField(
        this.interviewDate,
        null,
        null,
        reply ? reply.interviewDate : null,
      );
    },

    setOpeningDate() {
      let res = "";
      if (this.front) {
        if (this.user.type && this.questionnaire[this.user.type + "_infos"]) {
          if (this.questionnaire[this.user.type + "_infos"].start_date) {
            res = res.concat(
              "",
              this.$t("open_from") +
                " " +
                this.$d(
                  this.questionnaire[this.user.type + "_infos"].start_date,
                ),
            );
          }
          if (this.questionnaire[this.user.type + "_infos"].end_date) {
            res = res.concat(
              " - ",
              this.$d(this.questionnaire[this.user.type + "_infos"].end_date),
            );
          }
        }
      } else {
        let start = new Date();
        let end = new Date().setFullYear(start.getFullYear() + 1);
        if (this.questionnaire.defaultApprenticeAccessStartDate) {
          start = this.questionnaire.defaultApprenticeAccessStartDate;
        }
        if (this.questionnaire.defaultApprenticeAccessEndDate) {
          end = this.questionnaire.defaultApprenticeAccessEndDate;
        }
        res =
          this.$t("open_from") + " " + this.$d(start) + " - " + this.$d(end);
      }
      this.openingDate = res;
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire {
  min-height: 500px;
  position: relative;

  @include up-to-xl {
    margin-bottom: 20px;
  }

  .widget-content {
    padding: 20px;
  }
}

.block + .block {
  margin-top: 20px;
}

.answer-container + .answer-container,
.widget + .answer-container {
  margin-top: 5px;
}

.cluster {
  &__label {
    background: #00275e;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    padding: 5px 15px;
    border-radius: $radius;
    margin-bottom: 15px;
  }

  &__content {
    padding-left: 20px;
  }

  &__content + &__content {
    margin-top: 20px;
  }
}

.groupTitle {
  height: 35px;
  padding: 0 35px 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 100%;
  position: relative;

  .form-info {
    margin-bottom: 0;
  }
}
</style>
