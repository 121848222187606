import store from "@/store";
import { notificationManager } from "@/services/utilities/notification-manager";
import router from "@/router";
import { loginManager } from "@/services/security/login-manager";

class AccessManager {
  checkRoleAccess(to, from, next) {
    if (to.meta && to.meta.authRequired) {
      let user = store.state.user.user;
      if (!Object.keys(user).length) {
        loginManager.refreshConnection().then(() => {
          store.dispatch("fetchUserInfos").then(() => {
            let user = store.state.user.user;
            if (user && user.roles) {
              this.checkAccessByRoles(to, from, next, user);
            } else {
              router.push({ name: "Login" });
            }
          });
        });
      } else {
        if (user.roles) {
          this.checkAccessByRoles(to, from, next, user);
        } else {
          router.push({ name: "Login" });
        }
      }
    } else {
      return next();
    }
  }

  checkAccessByRoles(to, from, next, user) {
    if (user && user.roles) {
      if (
        to.meta.required_role &&
        !user.roles.includes(to.meta.required_role) &&
        !user.roles.includes("ROLE_STUDEA_ADMIN")
      ) {
        notificationManager
          .showAlert(
            "error",
            "Accès non autorisé",
            "Attention, vous n'avez pas les autorisations nécessaires pour accéder à cette page",
          )
          .then(() => {
            router.push(from);
          });
      } else {
        return next();
      }
    }
  }
}

export const accessManager = new AccessManager();
