import { userManager } from "@/services/security/user-manager";
import { getIdb, setIdb } from "@/indexed-db";
import store from "@/store";
import { pictureManager } from "@/services/utilities/picture-manager";

const state = {
  user: {},
  userPicture: "",
  usersList: {},
  userType: "",
  userProfile: null,
  userProfileName: "",
};

const mutations = {
  SET_USER_INFOS(state, user) {
    setIdb("ufaUser", JSON.stringify(user), "ufaUser");
    state.user = user;
  },
  SET_USER_INFO(state, user) {
    state.user = user;
  },
  INIT_USER_MODULE(state) {
    state.user = {};
  },
  SET_USER_PICTURE(state, picture) {
    state.userPicture = pictureManager.setPicture(picture);
  },
  SET_USER_LIST(state, list) {
    state.usersList = list;
  },

  SET_USER_PROFILE(state, userProfile) {
    state.userProfile = userProfile;
  },

  SET_USER_PROFILE_NAME(state, userProfileName) {
    state.userProfileName = userProfileName;
  },

  SET_USER_NEW_MESSAGE(state, userNewMessage) {
    state.user.userNewMessage = userNewMessage;
  },
};

const actions = {
  fetchUserInfos({ commit }) {
    if (store.state.isInternet) {
      return userManager.loadUserInfos().then((user) => {
        if (user) {
          commit("SET_USER_INFOS", user);
          commit("SET_USER_PICTURE", user.photo);
        }
      });
    } else {
      return getIdb("ufaUser", "ufaUser").then((user) => {
        commit("SET_USER_INFOS", JSON.parse(user));
        commit("SET_APP_LOADING", false);
      });
    }
  },

  postUserUpdate(context, payload) {
    return userManager
      .putUserInfos(payload.id, payload.datas)
      .then((success) => success);
  },

  postUserPicture({ commit }, data) {
    return userManager
      .postUserPicture(data, store.state.platform.design.upload_dir)
      .then((picture) => {
        commit("SET_USER_PICTURE", picture);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
