<template>
  <ModalFormComponent
    :models="models"
    :title="$t(modalTitle)"
    @submit="submitResearch"
  />

  <div class="dashboard__container">
    <BlockLoader :loading="loading" />

    <div
      :class="'widget dashboard__wrapper ' + (!isFullScreen ? 'editing' : '')"
    >
      <div class="widget-heading">
        <h2 class="textStyle">
          {{ $t("studea_manager.gradeBookDashboard.list.title") }}
        </h2>
        <ToggleFullScreenComponent
          :isFullScreen="isFullScreen"
          @toggle-full-screen="toggleFullScreen"
        />
      </div>
      <div class="position-relative">
        <FilterBlock @filter="filter" @reset="resetFilter" />
        <AccordionTableComponent
          v-if="!loading"
          :categories="categories"
          :crews="crews"
          :replies="replies"
          type="note"
        />
      </div>
    </div>
    <div :class="'action ' + (!isFullScreen ? 'editing' : '')">
      <ActionsBlock
        :actions="actions"
        :collapsable="false"
        :title="$t('global.actions.title')"
        collapseId="actions"
        @export-dashboard-to-excel="exportDashboardToExcel"
        @filter-questionnaire="filter"
      />
    </div>
  </div>
</template>

<script>
import AccordionTableComponent from "@/components/table/accordion/AccordionTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FilterBlock from "@/components/blocks/FilterBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { mapState } from "vuex";
import ToggleFullScreenComponent from "@/components/utilities/ToggleFullScreenComponent.vue";

export default {
  name: "DashboardView",
  components: {
    ToggleFullScreenComponent,
    BlockLoader,
    ModalFormComponent,
    AccordionTableComponent,
    ActionsBlock,
    FilterBlock,
  },
  data() {
    return {
      actions: [
        {
          translation:
            "studea_manager.gradeBookDashboard.export_dashboard_excel",
          icon: "file-excel",
          type: "success",
          actionType: "button",
          action: "exportDashboardToExcel",
          cfaStyle: false,
        },
      ],
      models: [],
      modalTitle: "",
      loading: true,
      isFullScreen: true,
      isMobile: window.innerWidth < 992,
    };
  },
  mounted() {
    this.loading = false;
    if (!this.isMobile) {
      this.$store.commit("TOGGLE_SIDE_BAR");
    }
  },
  computed: {
    ...mapState({
      categories: (state) => state.studeaManagerNoteDashboard.data.categories,
      crews: (state) => state.studeaManagerNoteDashboard.data.crews,
      replies: (state) => state.studeaManagerNoteDashboard.data.replies,
    }),
  },
  methods: {
    filter() {
      this.models = [];
      this.modalTitle = "";
      studeaManagerManager
        .filterForm("dashboard", this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
          this.modalTitle = "studea_manager.dashboard.filter";
        });
    },

    resetFilter() {
      this.models = [];
      this.modalTitle = "";
    },

    exportDashboardToExcel() {
      studeaManagerManager.exportToExcel("notes");
    },

    submitResearch(payload) {
      studeaManagerManager.submitDashboardFilter(payload);
      this.models = [];
      this.modalTitle = "";
    },

    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.$store.commit("TOGGLE_SIDE_BAR");
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard__container {
  width: 100%;
  display: flex;
  @include up-to-lg {
    flex-direction: column;
  }
}

.action,
.dashboard__wrapper {
  transition: all 300ms ease-in-out;
}

.action {
  overflow: hidden;
  width: 0%;

  @include up-to-lg {
    width: 100%;
    order: 1;
  }

  &.editing {
    width: 24%;
  }
}

.dashboard__wrapper {
  width: 100%;
  margin-right: 15px;

  &.editing {
    width: 75%;
  }

  @include up-to-lg {
    width: 100%;
    order: 2;
  }
}
</style>
