<template>
  <div v-if="active">
    <button
      :class="'chatbotToggle' + (open ? ' open' : '')"
      @click="openChatBot"
    >
      <img class="chatbot__logo" src="/assets/images/symbole.png" />
      Besoin d'aide ?
    </button>
    <div :class="'chatbot blockStyle2' + (open ? ' open' : '')">
      <div class="chatbot__heading">
        <img class="chatbot__logo" src="/assets/images/symbole.png" />
        <h2 class="textStyle">Chatbot Studea</h2>
        <button class="chatbot__close" @click="closeChatBot">
          <FontAwesomeIcon icon="fa-light fa-xmark" />
        </button>
      </div>
      <div ref="chatMessages" class="chatbot__messages">
        <div
          v-for="message in messages"
          :key="message"
          :class="'chatbot__row ' + (message.writer === 'me' ? 'me' : 'bot')"
        >
          <div class="chatbot__message textStyle">
            <div v-if="Object.keys(user).length" class="chatbot__writer">
              {{
                message.writer === "me" ? user.firstName : "Studea - Chatbot"
              }}
            </div>
            <div class="chatbot__text">{{ message.content }}</div>
          </div>
        </div>
        <div v-if="loading" class="chatbot__row bot">
          <div class="chatbot__message loading">
            <div class="chatbot__typing">
              <div class="dot-typing"></div>
            </div>
          </div>
        </div>
      </div>
      <form class="chatbot__form" @submit="post">
        <AvatarComponent
          v-if="Object.keys(user).length"
          :name="user.first_name + ' ' + user.last_name"
          :user="user"
          class="chatbot__avatar"
        ></AvatarComponent>
        <InputTypeComponent :model="model" />
        <button class="chatbot__submit" type="submit">
          <FontAwesomeIcon class="svgStyle" icon="fa-light fa-paper-plane" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";

export default {
  name: "ChatBotComponent",
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      messages: (state) => state.chatbot.messages,
      loading: (state) => state.chatbot.loading,
    }),
  },
  data() {
    return {
      model: {
        vars: {
          block_prefixes: ["", "text"],
          name: "user_chatbot",
          label: false,
          id: "user_chatbot",
          value: "",
          required: false,
          attr: {
            placeholder: "Posez votre question...",
            autocomplete: "off",
          },
          row_attr: {
            class: "m-0",
          },
        },
      },
      submit: {},
      open: false,
      active: false,
    };
  },
  // sockets    : {
  //   connect()
  //   {
  //     console.log('Connected to server');
  //   },
  //   disconnect()
  //   {
  //     console.log('Disconnected from server');
  //   },
  //   'chat message'(msg)
  //   {
  //     this.messages.push(msg);
  //   }
  // },
  methods: {
    post(e) {
      e.preventDefault();
      const $this = this;
      const container = $this.$refs.chatMessages;
      if (this.model.vars.value) {
        const message = {
          content: this.model.vars.value,
          writer: "me",
        };
        $this.$store.commit("STORE_MESSAGE", message);
        $this.$store.commit("CHATBOT_REQUEST");
        this.scrollToBottom(container);
        // this.$socket.emit('chat message', message);
        setTimeout(function () {
          const response = {
            content: "Oui, tout à fait !",
            writer: "chatbot",
            loading: false,
          };
          $this.$store.commit("STORE_MESSAGE", response);
          $this.$store.commit("CHATBOT_SUCCESS");
          $this.scrollToBottom(container);
        }, 2000);

        this.model.vars.value = "";
      }
    },
    openChatBot() {
      this.open = true;
      const container = this.$refs.chatMessages;
      container.scrollTop = container.scrollHeight;
    },
    closeChatBot() {
      this.open = false;
    },
    scrollToBottom(element) {
      setTimeout(function () {
        element.scroll({ top: element.scrollHeight, behavior: "smooth" });
      }, 5);
    },
  },
  components: {
    AvatarComponent,
    InputTypeComponent,
  },
};
</script>

<style lang="scss">
.chatbotToggle {
  position: fixed;
  bottom: 25px;
  left: 25px;
  font-weight: 700;
  background: $white;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  border-radius: 35px;
  padding: 0 30px 0 15px;
  border: 2px solid $lighter-grey;
  color: $blue;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: space-between;
  visibility: visible;
  opacity: 1;
  transition: all 300ms ease-in-out;

  &.open {
    visibility: hidden;
    opacity: 0;
  }

  img {
    width: 50px;
  }
}

.chatbot {
  position: fixed;
  left: 25px;
  bottom: 25px;
  width: 400px;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
  z-index: 10;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  input {
    padding-left: 50px;
    border-radius: 0;
    border: none;
  }

  &__heading {
    background: $white;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
    box-shadow: 0 6px 24px 5px rgba(0, 0, 0, 0.3);

    h2 {
      height: auto;
      margin-top: 0;
    }
  }

  &__logo {
    height: 100%;
  }

  &__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    background: none;
    border: none;
    color: $black;
    font-size: 22px;
  }

  &__writer {
    font-weight: 700;
  }

  &__form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -6px 24px 5px rgba(0, 0, 0, 0.3);
  }

  &__messages {
    padding: 25px 15px;
    height: calc(100% - 95px);
    overflow: scroll;
  }

  &__row {
    display: flex;
    justify-content: flex-end;

    &.bot {
      justify-content: flex-start;
    }
  }

  &__message {
    background: $white;
    border-radius: 10px;
    padding: 10px;
    width: 80%;

    &.loading {
      max-width: 45px;
      height: 25px;
    }
  }

  &__typing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__row + &__row {
    margin-top: 10px;
  }

  &__submit {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    bottom: 0;
    background: $white;
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
  }

  &__avatar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
  }
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
  position: relative;
  left: -9999px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  box-shadow:
    9990px 0 0 0 var(--primary-color),
    9999px 0 0 0 var(--primary-color),
    10008px 0 0 0 var(--primary-color);
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow:
      9990px 0 0 0 var(--primary-color),
      9999px 0 0 0 var(--primary-color),
      10008px 0 0 0 var(--primary-color);
  }
  16.667% {
    box-shadow:
      9990px -5px 0 0 var(--primary-color),
      9999px 0 0 0 var(--primary-color),
      10008px 0 0 0 var(--primary-color);
  }
  33.333% {
    box-shadow:
      9990px 0 0 0 var(--primary-color),
      9999px 0 0 0 var(--primary-color),
      10008px 0 0 0 var(--primary-color);
  }
  50% {
    box-shadow:
      9990px 0 0 0 var(--primary-color),
      9999px -5px 0 0 var(--primary-color),
      10008px 0 0 0 var(--primary-color);
  }
  66.667% {
    box-shadow:
      9990px 0 0 0 var(--primary-color),
      9999px 0 0 0 var(--primary-color),
      10008px 0 0 0 var(--primary-color);
  }
  83.333% {
    box-shadow:
      9990px 0 0 0 var(--primary-color),
      9999px 0 0 0 var(--primary-color),
      10008px -5px 0 0 var(--primary-color);
  }
  100% {
    box-shadow:
      9990px 0 0 0 var(--primary-color),
      9999px 0 0 0 var(--primary-color),
      10008px 0 0 0 var(--primary-color);
  }
}
</style>
