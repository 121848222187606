<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="loader-block">
        <div v-for="crew in crews" :key="crew" class="widget">
          <div class="widget-heading flex-column">
            <h6 class="textStyle">
              <router-link
                v-tooltip="{ title: $t('back') }"
                :to="{ name: 'studeaManagerTriadList' }"
                class="btn-icon"
              >
                <FontAwesomeIcon icon="fa-light fa-arrow-left" />
              </router-link>
              <span class="ms-2">{{ crew.title }} (Réf : {{ crew.id }})</span>
            </h6>
            <p class="textStyle">
              {{ $t("studea_manager.triad.send_user_infos_message") }}
            </p>
          </div>
          <div class="widget-content">
            <div class="list">
              <div class="list__triad -heading">
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('apprentice.name', crew)"
                >
                  <div>
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ apprenticeProfileLabel.title }}<br />
                    <div>
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'apprentice', crew)"
                      >
                        {{ $t("All") }}
                      </button>
                      /
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'apprentice', crew, false)"
                      >
                        {{ $t("None") }}
                      </button>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('tutor.name', crew)"
                >
                  <div>
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ tutorProfileLabel.title }}<br />
                    <div>
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'tutor', crew)"
                      >
                        {{ $t("All") }}
                      </button>
                      /
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'tutor', crew, false)"
                      >
                        {{ $t("None") }}
                      </button>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div class="list__column textStyle">
                  {{ $t("studea_manager.triad.list.name") }} :
                  {{ apprenticeMasterProfileLabel.title }}<br />
                  <div>
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'apprenticeMaster', crew)"
                    >
                      {{ $t("All") }}
                    </button>
                    /
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'apprenticeMaster', crew, false)"
                    >
                      {{ $t("None") }}
                    </button>
                  </div>
                </div>
                <div class="list__column textStyle">
                  {{ $t("studea_manager.triad.list.name") }} :
                  {{ companyManagerProfileLabel.title }}<br />
                  <div>
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'gestionnary', crew)"
                    >
                      {{ $t("All") }}
                    </button>
                    /
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'gestionnary', crew, false)"
                    >
                      {{ $t("None") }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-for="triad in crew.triads"
                :key="triad"
                class="list__triad"
              >
                <div class="list__column">
                  <div
                    v-if="triad.apprentice"
                    class="d-flex align-items-center"
                  >
                    <ListCheckBoxInputComponent
                      v-if="triad.apprentice"
                      :id="triad.apprentice.id"
                      :checkedList="listCheck"
                      @checked="check"
                    />
                    <div class="badge -apprentice">A</div>
                    <div
                      :class="{ green: triad.apprentice.alreadyConnectedOnce }"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>{{ triad.apprentice.fullName }}</span>
                        <div>
                          <div
                            v-if="!triad.apprentice.alreadyConnectedOnce"
                            class="btn btn-icon"
                          >
                            <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                          </div>
                          <button
                            v-if="triad.apprentice.mail"
                            class="btn btn-icon ms-1"
                          >
                            <FontAwesomeIcon icon="fa-light fa-envelope" />
                          </button>
                          <button
                            v-if="triad.apprentice.resetNumber"
                            class="btn btn-icon ms-1"
                          >
                            <FontAwesomeIcon icon="fa-light fa-key" />
                          </button>
                          <button
                            v-if="triad.apprentice.welcomeNumber"
                            class="btn btn-icon ms-1"
                          >
                            <FontAwesomeIcon icon="fa-light fa-house" />
                          </button>
                        </div>
                      </div>
                      <div>{{ triad.apprentice.email }}</div>
                    </div>
                  </div>
                </div>
                <div class="list__column">
                  <div
                    v-if="triad.tutor"
                    class="d-flex justify-content-between"
                  >
                    <div class="d-flex align-items-center">
                      <ListCheckBoxInputComponent
                        v-if="triad.tutor"
                        :id="triad.tutor.id"
                        :checkedList="listCheck"
                        @checked="check"
                      />
                      <div class="badge -tutor">T</div>
                      <div :class="{ green: triad.tutor.alreadyConnectedOnce }">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>{{ triad.tutor.fullName }}</span>
                          <div>
                            <div
                              v-if="!triad.tutor.alreadyConnectedOnce"
                              class="btn btn-icon"
                            >
                              <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                            </div>
                            <button
                              v-if="triad.tutor.mail"
                              class="btn btn-icon ms-1"
                            >
                              <FontAwesomeIcon icon="fa-light fa-envelope" />
                            </button>
                            <button
                              v-if="triad.tutor.resetNumber"
                              class="btn btn-icon ms-1"
                            >
                              <FontAwesomeIcon icon="fa-light fa-key" />
                            </button>
                            <button
                              v-if="triad.tutor.welcomeNumber"
                              class="btn btn-icon ms-1"
                            >
                              <FontAwesomeIcon icon="fa-light fa-house" />
                            </button>
                          </div>
                        </div>
                        <div>{{ triad.tutor.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list__column">
                  <div
                    v-if="triad.apprenticeMaster"
                    class="d-flex justify-content-between"
                  >
                    <div class="d-flex align-items-center">
                      <ListCheckBoxInputComponent
                        v-if="triad.apprenticeMaster"
                        :id="triad.apprenticeMaster.id"
                        :checkedList="listCheck"
                        @checked="check"
                      />
                      <div class="badge -apprenticeMaster">M</div>
                      <div
                        :class="{
                          green: triad.apprenticeMaster.alreadyConnectedOnce,
                        }"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>{{ triad.apprenticeMaster.fullName }}</span>
                          <div>
                            <div
                              v-if="
                                !triad.apprenticeMaster.alreadyConnectedOnce
                              "
                              class="btn btn-icon"
                            >
                              <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                            </div>
                            <button
                              v-if="triad.apprenticeMaster.mail"
                              class="btn btn-icon ms-1"
                            >
                              <FontAwesomeIcon icon="fa-light fa-envelope" />
                            </button>
                            <button
                              v-if="triad.apprenticeMaster.resetNumber"
                              class="btn btn-icon ms-1"
                            >
                              <FontAwesomeIcon icon="fa-light fa-key" />
                            </button>
                            <button
                              v-if="triad.apprenticeMaster.welcomeNumber"
                              class="btn btn-icon ms-1"
                            >
                              <FontAwesomeIcon icon="fa-light fa-house" />
                            </button>
                          </div>
                        </div>
                        <div>{{ triad.apprenticeMaster.email }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list__column">
                  <ListCheckBoxInputComponent
                    v-if="triad.gestionnary"
                    :id="triad.gestionnary.id"
                    :checkedList="listCheck"
                    @checked="check"
                  />
                  <div
                    v-if="triad.gestionnary"
                    :class="{ green: triad.gestionnary.alreadyConnectedOnce }"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ triad.gestionnary.fullName }}</span>
                      <div>
                        <div
                          v-if="!triad.gestionnary.alreadyConnectedOnce"
                          class="btn btn-icon"
                        >
                          <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                        </div>
                        <button
                          v-if="triad.gestionnary.mail"
                          class="btn btn-icon ms-1"
                        >
                          <FontAwesomeIcon icon="fa-light fa-envelope" />
                        </button>
                        <button
                          v-if="triad.gestionnary.resetNumber"
                          class="btn btn-icon ms-1"
                        >
                          <FontAwesomeIcon icon="fa-light fa-key" />
                        </button>
                        <button
                          v-if="triad.gestionnary.welcomeNumber"
                          class="btn btn-icon ms-1"
                        >
                          <FontAwesomeIcon icon="fa-light fa-house" />
                        </button>
                      </div>
                    </div>
                    <div>{{ triad.gestionnary.email }}</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :collapsable="false"
            :title="$t('global.actions.title')"
            collapseId="actions"
            @resetPassword="resetPassword"
            @sendWelcomeEmail="sendWelcomeEmail"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { sortManager } from "@/services/utilities/sort-manager";
import ListCheckBoxInputComponent from "@/components/form/ListCheckBoxInputComponent.vue";
import { mapState } from "vuex";

export default {
  name: "SendUserInfosView",
  components: {
    ListCheckBoxInputComponent,
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      crews: [],
      listCheck: [],
      loading: false,
      sortWay: "ASC",
      actions: [
        {
          translation: "studea_manager.triad.send_welcome_email",
          icon: "house",
          type: "success",
          actionType: "button",
          action: "sendWelcomeEmail",
          cfaStyle: false,
          disabled: true,
        },
        {
          translation: "studea_manager.triad.reset_password",
          icon: "key",
          type: "success",
          actionType: "button",
          action: "resetPassword",
          cfaStyle: false,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      apprenticeProfileLabel: (state) => state.platform.apprenticeProfileLabel,
      tutorProfileLabel: (state) => state.platform.tutorProfileLabel,
      apprenticeMasterProfileLabel: (state) =>
        state.platform.apprenticeMasterProfileLabel,
      companyManagerProfileLabel: (state) =>
        state.platform.companyManagerProfileLabel,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      studeaManagerManager
        .list("triad", this.$route.params.trainingCourseId, false, [])
        .then((res) => {
          this.crews = res;
          this.loading = false;
        });
    },

    check(payload) {
      if (!this.listCheck.includes(payload.id)) {
        this.listCheck.push(payload.id);
      }
      if (!payload.checked) {
        const index = this.listCheck.indexOf(payload.id);
        this.listCheck.splice(index, 1);
      }

      this.update();
    },

    checkAll(e, key, crew, all = true) {
      e.stopPropagation();
      Object.values(crew.triads).forEach((triad) => {
        this.check({ id: triad[key].id, checked: all });
      });
    },

    update() {
      this.actions[0].disabled = !Object.keys(this.listCheck).length;
      this.actions[1].disabled = !Object.keys(this.listCheck).length;
    },

    sort(field, crew) {
      crew.sortWay = crew.sortWay && crew.sortWay === "DESC" ? "ASC" : "DESC";
      crew.triads = sortManager.sort(crew.triads, field, crew.sortWay);
    },

    sendWelcomeEmail() {
      this.loading = true;
      studeaManagerManager
        .sendWelcome(this.listCheck, this.$route.params.trainingCourseId)
        .then(() => {
          this.init();
          this.listCheck = [];
        });
    },

    resetPassword() {
      this.loading = true;
      studeaManagerManager
        .sendNewPassword(this.listCheck, this.$route.params.trainingCourseId)
        .then(() => {
          this.init();
          this.listCheck = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  .green {
    color: $green;
  }

  &__triad {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    @include up-to-md {
      flex-direction: column;
    }

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__checkAll {
    background: none;
    border: none;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__column {
    width: 25%;
    padding: 0 15px 0 25px;
    position: relative;

    @include up-to-md {
      width: 100%;
    }

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }

  .badge {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    font-size: 10px;
    background: $orange;
    display: none;
    margin-right: 5px;

    @include up-to-md {
      display: flex;
    }

    &.-apprentice {
      background: $green;
    }

    &.-tutor {
      background: $purple;
    }

    &.-apprenticeMaster {
      background: $red;
    }
  }
}
</style>
