<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-0">
        <div class="sticky">
          <TriadMemberBlock />
        </div>
      </div>
      <div
        :class="
          'order-1 order-lg-1' +
          (!isStudeaManager() ? ' col-lg-6' : ' col-lg-9')
        "
        v-if="!editing"
      >
        <div class="widget p-3">
          <ul
            class="nav nav-pills"
            id="justify-center-pills-tab"
            role="tablist"
          >
            <li class="nav-item" v-for="(tab, index) in documents" :key="tab">
              <a
                :class="
                  'nav-link' +
                  ((activeTab ? activeTab === tab.code : index === 0)
                    ? ' active'
                    : '')
                "
                :id="tab.code + '-tab'"
                data-bs-toggle="pill"
                :href="'#' + tab.code"
                role="tab"
                :aria-controls="tab.code"
                :aria-selected="index === '0'"
                >{{
                  tab.title
                    ? tab.title
                    : $t("studea_manager.document." + tab.code + "_documents")
                }}</a
              >
            </li>
          </ul>
        </div>
        <div class="tab-content" id="justify-center-pills-tabContent">
          <div
            v-for="(tab, index) in documents"
            :key="tab"
            :class="
              'tab-pane pt-0 fade' +
              ((activeTab ? activeTab === tab.code : index === 0)
                ? ' show active'
                : '')
            "
            :id="tab.code"
            role="tabpanel"
            :aria-labelledby="tab.code + '-tab'"
          >
            <div class="widget">
              <div class="widget-heading">
                <h2 class="textStyle">
                  {{ tab.title ? tab.title : $t(tab.code + "Documents") }}
                </h2>
              </div>
              <div class="widget-content position-relative">
                <div v-if="Object.keys(tab.documents).length">
                  <SimpleListComponent
                    :actions="listActions"
                    :datas="tab.documents"
                  />
                </div>
                <div v-else class="widget-content">
                  {{ $t("none_document") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="editing" class="col-lg-6 order-1 order-lg-1">
        <FormBlockComponent
          :loading="formLoading"
          :models="models"
          :title="modelTitle"
          @formCancel="formCancel"
          @submit="submit"
        />
      </div>
      <div
        class="col-lg-3 order-lg-2 order-0 order-lg-1"
        v-if="!isStudeaManager()"
      >
        <div class="sticky">
          <ActionsBlock :actions="generalActions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleListComponent from "@/components/table/SimpleListComponent.vue";
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { mapState } from "vuex";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { documentManager } from "@/services/document/document-manager";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "DocumentView",
  components: {
    ActionsBlock,
    TriadMemberBlock,
    SimpleListComponent,
    FormBlockComponent,
  },
  data() {
    return {
      editing: false,
      models: [],
      modelTitle: "",
      formLoading: false,
      pageTitle: "",
      activeTab: null,
      listActions: [
        {
          translation: "download_file",
          icon: "download",
          type: "primary",
          actionType: "externalLink",
          downloadKey: "documentFile",
          cfaStyle: true,
        },
      ],
      generalActions: [
        {
          translation: "new_doc",
          icon: "file",
          type: "primary",
          action: "dashboardAddDoc",
          actionType: "router",
          cfaStyle: true,
          params: { alias: "add" },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
      documents: (state) => state.triad.documents,
      design: (state) => state.platform.design,
      apprenticeProfileLabel: (state) => state.platform.apprenticeProfileLabel,
      tutorProfileLabel: (state) => state.platform.tutorProfileLabel,
      apprenticeMasterProfileLabel: (state) =>
        state.platform.apprenticeMasterProfileLabel,
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
    }),
  },
  watch: {
    $route() {
      this.loading = true;
      this.editing =
        this.$route.name === "dashboardAddDoc" ||
        this.$route.name === "dashboardAddNote";
      if (this.editing) {
        this.initModel();
      }
    },
    trainingCourse() {
      if (this.editing) {
        this.initModel();
      }
    },
    triad() {
      if (this.editing) {
        this.initModel();
      }
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_DOCUMENT,
      );
    },
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_DOCUMENT,
    );
  },
  mounted() {
    this.editing =
      this.$route.name === "dashboardAddDoc" ||
      this.$route.name === "dashboardAddNote";

    if (this.editing && (this.documents || this.documentPedagogic)) {
      this.initModel();
    }

    if (this.$route.query.category) {
      this.activeTab = this.$route.query.category;
    }
  },
  methods: {
    isStudeaManager() {
      return (
        (this.trainingCourse && this.trainingCourse.isStudeaManager) ||
        (this.user &&
          this.user.roles &&
          this.user.roles.includes("ROLE_STUDEA_ADMIN"))
      );
    },

    initModel() {
      if (
        this.trainingCourse &&
        this.trainingCourse.id &&
        this.triad &&
        this.triad.id
      ) {
        this.models = [];
        this.modelTitle = "";
        this.formLoading = true;
        documentManager
          .form(this.trainingCourse.id, this.triad.id)
          .then((data) => {
            this.models = data;
            this.formLoading = false;
            this.modelTitle = this.$t(
              this.$route.name === "dashboardAddNote" ? "new_note" : "new_doc",
            );
          });
        this.loading = false;
      }
    },

    remove(row, type) {
      let alertTitle = "";
      switch (type) {
        case 1:
          alertTitle = this.$t("document_remove_confirm");
          break;
        case 2:
          alertTitle = this.$t("note_remove_confirm");
          break;
      }
      notificationManager
        .showAlert("warning", alertTitle, "", false, true)
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("removeDocument", { id: row.id, type: type });
          }
        });
    },

    submit(payload) {
      let params = formManager.processForm(payload);
      documentManager
        .post(this.trainingCourse.id, params, this.triad.id)
        .then(() => {
          this.$router.push({ name: "MODULE_STUDEA_DOCUMENT" });
        });
    },

    formCancel() {
      this.$router.push({ name: "MODULE_STUDEA_DOCUMENT" });
      this.editing = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
