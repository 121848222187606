<template>
  <div :id="itemModel.vars.id" ref="radioGroup">
    <label
      v-if="itemModel.vars.label"
      :class="
        'form-label' +
        (itemModel.vars.label_attr && itemModel.vars.label_attr.class
          ? ' ' + itemModel.vars.label_attr.class
          : '') +
        (itemModel.vars.required ? ' required' : '')
      "
    >
      {{ index ? index + ". " : "" }}{{ itemModel.vars.label
      }}{{ itemModel.vars.required ? "*" : "" }}
      <span
        v-if="itemModel.vars.help && !isMobile()"
        v-tooltip="{ title: itemModel.vars.help }"
      >
        <FontAwesomeIcon icon="fa-light fa-circle-question" />
      </span>
    </label>
    <div v-if="isMobile() && itemModel.vars.help" class="form-info">
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
      {{ itemModel.vars.help }}
    </div>
    <div
      :class="itemModel.direction ? itemModel.direction : ''"
      v-if="!itemModel.vars.hide"
    >
      <div
        v-for="choice in itemModel.vars.choices"
        :key="choice"
        class="text-color radio-primary custom-control custom-radio"
      >
        <input
          :id="'radio-' + itemModel.vars.name + '-' + choice.value"
          v-model="itemModel.vars.value"
          :class="
            (itemModel.vars.error
              ? 'custom-control-input is-invalid'
              : 'custom-control-input') +
            (itemModel.vars.attr && itemModel.vars.attr.class
              ? ' ' + itemModel.vars.attr.class
              : '')
          "
          :disabled="itemModel.vars.disabled === true"
          :name="itemModel.vars.name"
          :value="choice.data"
          type="radio"
          @change="valid($event)"
          @focus="focus"
        />
        <label
          :for="'radio-' + itemModel.vars.name + '-' + choice.value"
          class="custom-control-label"
          ><span :class="choice.label ? 'ms-2' : ''">{{
            choice.label
          }}</span></label
        >
      </div>
    </div>
  </div>
  <div
    v-if="model.vars.error"
    :class="'invalid-feedback d-block' + (model.vars.row ? ' text-center' : '')"
  >
    {{ model.vars.error }}
  </div>
</template>

<script>
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "RadioTypeComponent",
  data() {
    return {
      itemModel: this.model,
      key: 0,
    };
  },
  props: {
    model: {
      type: [],
      required: true,
      default: [],
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    "model.vars.value"() {
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
    },
    "model.vars.error"() {
      this.getClass();
    },
  },
  methods: {
    valid(event) {
      this.itemModel.vars.value = event.target.value;
      this.$emit("update", this.itemModel.vars.value);
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
      this.$emit("draft", this.itemModel);

      formValidator.validModel(this.itemModel, this.language);
      this.getClass();
    },
    getClass() {
      this.key++;
      const group = document.getElementById(this.itemModel.vars.id);
      const targets = group.querySelectorAll(".custom-control-input");
      if (
        !this.itemModel.vars.value ||
        (this.itemModel.vars.value &&
          !this.itemModel.vars.value.toString().length)
      ) {
        for (let i = 0; i < targets.length; i++) {
          targets[i].classList.add("is-invalid");
        }
      } else {
        for (let i = 0; i < targets.length; i++) {
          targets[i].classList.add("is-valid");
        }
      }
    },
    isMobile() {
      return window.innerWidth < 992;
    },
    focus() {
      this.$emit("focus");
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.form-radio {
  padding: 15px;
  border: 2px solid $dark-grey;
  border-radius: $radius;
  margin: 10px 0;
}

.form-radio-row {
  display: flex;

  div + div {
    margin-left: 15px;
  }
}

.is-invalid + label {
  &:before {
    border-color: $red;
  }
}
</style>
