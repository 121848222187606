<template>
  <div class="widget d-flex align-items-center">
    <div
      class="d-flex justify-content-between align-items-center w-100 flex-wrap"
    >
      <div class="d-flex flex-wrap">
        <div
          :class="
            'd-flex align-items-center tab__btn ' +
            (currentSession && currentSession.id === session.id ? 'active' : '')
          "
          v-for="session in sessions"
          :key="session.id"
        >
          <span
            v-tooltip="{
              title:
                $t('studea_manager.session.from') +
                ' ' +
                this.formatDate(session.startDate) +
                ' ' +
                $t('studea_manager.session.to') +
                ' ' +
                this.formatDate(session.endDate),
            }"
            class="me-2 -info"
            ><FontAwesomeIcon icon="fa-light fa-circle-info" />
          </span>
          <button class="pointer" @click="changeSession(session.id)">
            {{ session.name }}
          </button>
          <FontAwesomeIcon
            v-if="!isStudeaManagerRoute"
            class="ms-2"
            :icon="'fa-light fa-' + (session.open ? 'hourglass' : 'lock')"
          />
          <router-link
            :to="{
              name: 'studeaManagerSkillSessionEdit',
              params: { alias: 'skill-session', id: session.id },
            }"
            v-if="isStudeaManagerRoute"
            class="ms-3 ms-md-2 btn-icon -white"
            v-tooltip="{
              title: $tc('global.edit.title', 2, {
                name: this.$tc(
                  'studea_manager.skillSession.title',
                  1,
                ).toLowerCase(),
              }),
            }"
          >
            <FontAwesomeIcon icon="fa-light fa-pen" />
          </router-link>
          <button
            v-if="isStudeaManagerRoute"
            class="btn-icon -white ms-md-0 ms-2"
            v-tooltip="{
              title: $tc('global.delete.title', 2, {
                name: this.$tc(
                  'studea_manager.skillSession.title',
                  1,
                ).toLowerCase(),
              }),
            }"
            @click="deleteSession(session.id)"
          >
            <FontAwesomeIcon icon="fa-light fa-trash" />
          </button>
        </div>
      </div>
      <div v-if="isStudeaManagerRoute">
        <router-link
          class="list__button btn-icon"
          :to="{
            name: 'studeaManagerSkillSessionAdd',
            params: {
              alias: 'skill-session',
              params: { trainingCourseId: $route.params.trainingCourseId },
            },
          }"
          v-tooltip="{
            title: $tc('global.add.title', 2, {
              name: $tc('studea_manager.skillSession.title', 1).toLowerCase(),
            }),
          }"
        >
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "SessionTabsBlock",
  components: { FontAwesomeIcon },
  data() {
    return {
      isStudeaManagerRoute: false,
    };
  },
  props: {
    sessions: {
      type: [],
      required: true,
    },
    currentSession: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.isStudeaManagerRoute = this.$route.path
      .split("/")
      .includes("studea-manager");
  },
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    changeSession(id) {
      this.$emit("changeSession", id);
    },

    deleteSession(id) {
      this.$emit("deleteSession", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px;
}

.tab__btn {
  position: relative;
  border: none;
  border-radius: 5px;
  padding: 8px;
  background-color: #f1f2f3;
  color: var(--primary-color);
  font-size: 1rem;
  margin-right: 10px;
  cursor: default;

  @include up-to-md {
    margin-bottom: 10px;
  }

  .pointer,
  .btn-icon {
    color: var(--primary-color);
  }

  &.active {
    background-color: var(--primary-color);
    color: $white;

    .pointer,
    .btn-icon {
      color: $white;
    }
  }
}

.pointer {
  background: none;
  border: none;
  padding: 0;
}

.list__button.btn-icon {
  svg {
    width: 22px;
    height: 22px;
  }
}

.-info {
  @include up-to-md {
    display: none;
  }
}
</style>
