<template>
  <h1 class="">
    <span class="fw-normal fs-6">{{ $t("loginTo") }}</span
    ><br />
    <span class="brand-name textStyle">{{ name }}</span>
  </h1>
  <div v-if="message" class="badge badge-danger me-1 mt-1 w-100">
    {{ message }}
  </div>
  <form class="text-start">
    <div class="form">
      <div id="username-field" class="field-wrapper input">
        <svg
          class="feather feather-user svgStyle"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <input
          v-model="login"
          :placeholder="$t('username')"
          class="form-control"
          type="text"
          @input="checkInput('login')"
        />
        <div v-if="loginError" class="invalid-feedback d-block">
          {{ loginError }}
        </div>
      </div>

      <div id="password-field" class="field-wrapper input mb-2">
        <svg
          class="feather feather-lock svgStyle"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>
        <input
          v-if="showPassword"
          v-model="password"
          :placeholder="$t('password')"
          class="form-control"
          type="text"
          @input="checkInput('password')"
        />
        <input
          v-else
          v-model="password"
          :placeholder="$t('password')"
          class="form-control"
          type="password"
          @input="checkInput('password')"
        />
        <div v-if="passwordError" class="invalid-feedback d-block">
          {{ passwordError }}
        </div>
      </div>
      <div class="d-sm-flex justify-content-between">
        <div class="field-wrapper toggle-pass d-flex align-items-center">
          <p class="d-inline-block">{{ $t("showPassword") }}</p>
          <label class="switch s-primary mx-2">
            <input
              v-model="showPassword"
              class="custom-control-input"
              type="checkbox"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="field-wrapper text-center">
          <button
            class="btn btn-primary blockStyle"
            type="submit"
            @click="loginCheck($event)"
          >
            {{ $t("login") }}
          </button>
        </div>
      </div>

      <div class="field-wrapper text-center keep-logged-in">
        <div class="checkbox-outline-primary custom-control custom-checkbox">
          <input
            id="chkRemember"
            v-model="stayConnected"
            class="custom-control-input"
            type="checkbox"
            value="true"
          />
          <label class="custom-control-label" for="chkRemember">{{
            $t("keepMeLoggedIn")
          }}</label>
        </div>
      </div>

      <div class="field-wrapper">
        <router-link
          class="forgot-pass-link"
          :to="{ name: 'loginResetPassword' }"
          >{{ $t("forgotPasswordLink") }}
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import "@/assets/sass/authentication/auth.scss";
import { loginManager } from "@/services/security/login-manager";

export default {
  name: "WebLoginView",
  components: {},
  data() {
    return {
      login: "",
      loginError: "",
      password: "",
      message: "",
      passwordError: "",
      showPassword: false,
      stayConnected: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      design: (state) => state.platform.design,
      name: (state) => state.platform.name,
      trainingCourses: (state) => state.trainingCourse.trainingCourseList,
    }),
  },
  methods: {
    loginCheck(e) {
      e.preventDefault();
      if (this.login && this.password) {
        loginManager
          .login(this.login, this.password, this.stayConnected)
          .then((response) => {
            if (!response.success) {
              this.message = this.$t("badCredentials");
            } else {
              this.$store.commit("SET_APP_LOADING", true);
              window.localStorage.removeItem("studea-oldTriad");
              window.localStorage.removeItem("studea-oldTrainingCourse");
              this.message = "";
              this.$store.dispatch("fetchUserInfos").then(() => {
                this.$store.dispatch("fetchTrainingCourseList", {}).then(() => {
                  if (
                    this.user &&
                    this.user.roles &&
                    this.user.roles.includes("ROLE_STUDEA_ADMIN")
                  ) {
                    this.$store.commit("SET_APP_LOADING", false);
                    window.localStorage.setItem(
                      "studea-returnPathName",
                      JSON.stringify({ name: "TrainingCourseList" }),
                    );
                    this.$router.push({ name: "adminHome" });
                  } else if (Object.keys(this.trainingCourses).length > 1) {
                    this.$store.commit("SET_APP_LOADING", false);
                    this.$router.push({ name: "TrainingCourseList" });
                  } else {
                    this.$store.commit("SET_APP_LOADING", false);
                    this.$router.push({ name: "Dashboard" });
                  }
                });
              });
            }
          });
      } else {
        if (!this.login) {
          this.loginError = this.$t("requiredField");
        } else {
          this.loginError = "";
        }

        if (!this.password) {
          this.passwordError = this.$t("requiredField");
        } else {
          this.passwordError = "";
        }
      }
    },
    checkInput(input) {
      if (!this.$data[`${input}`]) {
        this.$data[`${input}Error`] = this.$t("requiredField");
      } else {
        this.$data[`${input}Error`] = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
