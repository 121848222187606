<template>
  <div
    :id="'questionnaire-block-' + year.year"
    :class="'widget' + (accordion ? ' accordion' : '')"
  >
    <div
      :id="'questionnaire-toggle-' + year.year"
      :aria-expanded="false"
      :class="'widget-heading' + (!openCollapse ? ' collapsed' : '')"
      :aria-controls="'collapse-questionnaire-' + year.year"
      :data-bs-toggle="accordion ? 'collapse' : ''"
      :data-bs-target="accordion ? '#collapse-questionnaire-' + year.year : ''"
      role="tab"
      @click="expand($event, year.year)"
    >
      <h2
        class="textStyle"
        v-if="questionnaireConfig && questionnaireConfig.termQuestionnaire"
      >
        {{ fistLetterToUppercase(questionnaireConfig.termQuestionnaire) }}
        <span v-if="Object.keys(questionnaires).length !== 1">{{
          year.year
        }}</span>
      </h2>
      <FontAwesomeIcon
        v-if="accordion"
        class="chevron"
        icon="fa-light fa-chevron-up"
      />
    </div>
    <div
      :id="'collapse-questionnaire-' + year.year"
      :class="!openCollapse ? 'collapse' : 'collapse show'"
      :aria-labelledby="'collapse-questionnaire-' + year.year"
    >
      <div class="widget-content">
        <div
          v-for="category in year.categories"
          :key="category"
          class="category"
        >
          <div class="category__heading">
            <div
              :class="
                'category__title -heading blockStyle2 secondary-color' +
                (hideIcons ? ' w-100' : '')
              "
            >
              {{ category.title }}
            </div>
            <div v-if="!hideIcons" class="category__roles -heading blockStyle">
              <div
                v-if="
                  apprenticeProfileLabel &&
                  apprenticeProfileLabel.title &&
                  apprenticeProfileLabel.titleAbbreviation
                "
                class="category__role"
                v-tooltip="{ title: apprenticeProfileLabel.title }"
              >
                {{ apprenticeProfileLabel.titleAbbreviation }}
              </div>
              <div
                v-if="
                  tutorProfileLabel &&
                  tutorProfileLabel.title &&
                  tutorProfileLabel.titleAbbreviation
                "
                :title="tutorProfileLabel.title"
                class="category__role"
                v-tooltip="{ title: tutorProfileLabel.title }"
              >
                {{ tutorProfileLabel.titleAbbreviation }}
              </div>
              <div
                v-if="
                  apprenticeMasterProfileLabel &&
                  apprenticeMasterProfileLabel.title &&
                  apprenticeMasterProfileLabel.titleAbbreviation
                "
                class="category__role"
                v-tooltip="{ title: apprenticeMasterProfileLabel.title }"
              >
                {{ apprenticeMasterProfileLabel.titleAbbreviation }}
              </div>
              <div
                v-if="
                  triad.studeaManagerColumn &&
                  studeaManagerProfileLabel &&
                  studeaManagerProfileLabel.titleAbbreviation
                "
                class="category__role"
              >
                {{ studeaManagerProfileLabel.titleAbbreviation }}
              </div>
            </div>
          </div>
          <div
            v-for="questionnaire in category.questionnaires"
            :key="questionnaire"
            class="category__line"
          >
            <span v-tooltip="{ title: info(questionnaire) }" class="info">
              <FontAwesomeIcon icon="fa-light fa-circle-info" />
            </span>
            <router-link
              :to="{
                name: 'questionnaire',
                params: {
                  questionnaireId: questionnaire.id,
                  triadId: triad.id,
                },
              }"
              class="category__title"
            >
              {{ questionnaire.title }}
            </router-link>
            <div v-if="!hideIcons" class="category__roles">
              <button
                v-if="questionnaire.profileStatus.apprentice"
                :key="key"
                :disabled="!canManage(questionnaire.profileStatus.apprentice)"
                class="category__role textStyle"
                v-tooltip="{
                  title: canManage(questionnaire.profileStatus.apprentice)
                    ? questionnaire.profileStatus.apprentice.icon === 'check'
                      ? $t(
                          'studea_manager.dashboard.questionnaire.cancel_signature',
                        )
                      : $t(
                          'studea_manager.dashboard.questionnaire.unlock_questionnaire',
                        )
                    : '',
                }"
                @click="
                  manage(
                    questionnaire.profileStatus.apprentice,
                    1,
                    questionnaire.id,
                  )
                "
              >
                <FontAwesomeIcon
                  :icon="'fa fa-' + questionnaire.profileStatus.apprentice.icon"
                />
              </button>
              <button
                v-if="questionnaire.profileStatus.tutor"
                :disabled="!canManage(questionnaire.profileStatus.tutor)"
                :key="key"
                class="category__role textStyle"
                v-tooltip="{
                  title: canManage(questionnaire.profileStatus.tutor)
                    ? questionnaire.profileStatus.tutor.icon === 'check'
                      ? $t(
                          'studea_manager.dashboard.questionnaire.cancel_signature',
                        )
                      : $t(
                          'studea_manager.dashboard.questionnaire.unlock_questionnaire',
                        )
                    : '',
                }"
                @click="
                  manage(questionnaire.profileStatus.tutor, 2, questionnaire.id)
                "
              >
                <FontAwesomeIcon
                  :icon="'fa fa-' + questionnaire.profileStatus.tutor.icon"
                />
              </button>
              <button
                v-if="questionnaire.profileStatus.apprenticeMaster"
                :disabled="
                  !canManage(questionnaire.profileStatus.apprenticeMaster)
                "
                :key="key"
                class="category__role textStyle"
                v-tooltip="{
                  title: canManage(questionnaire.profileStatus.apprenticeMaster)
                    ? questionnaire.profileStatus.apprenticeMaster.icon ===
                      'check'
                      ? $t(
                          'studea_manager.dashboard.questionnaire.cancel_signature',
                        )
                      : $t(
                          'studea_manager.dashboard.questionnaire.unlock_questionnaire',
                        )
                    : '',
                }"
                @click="
                  manage(
                    questionnaire.profileStatus.apprenticeMaster,
                    3,
                    questionnaire.id,
                  )
                "
              >
                <FontAwesomeIcon
                  :icon="
                    'fa fa-' + questionnaire.profileStatus.apprenticeMaster.icon
                  "
                />
              </button>
              <button
                v-if="
                  triad.studeaManagerColumn &&
                  questionnaire.profileStatus.studeaManager
                "
                :key="key"
                :disabled="
                  !canManage(questionnaire.profileStatus.studeaManager)
                "
                @click="
                  manage(
                    questionnaire.profileStatus.studeaManager,
                    4,
                    questionnaire.id,
                  )
                "
                class="category__role textStyle"
                v-tooltip="{
                  title: canManage(questionnaire.profileStatus.studeaManager)
                    ? questionnaire.profileStatus.studeaManager.icon === 'check'
                      ? $t(
                          'studea_manager.dashboard.questionnaire.cancel_signature',
                        )
                      : $t(
                          'studea_manager.dashboard.questionnaire.unlock_questionnaire',
                        )
                    : '',
                }"
              >
                <FontAwesomeIcon
                  :icon="
                    'fa fa-' + questionnaire.profileStatus.studeaManager.icon
                  "
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "QuestionnaireListBlockComponent",
  props: {
    hideIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
    accordion: {
      type: Boolean,
      required: false,
      default: false,
    },
    year: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {
      loading: false,
      todayYear: new Date().getFullYear(),
      openCollapse: false,
      key: 0,
    };
  },
  computed: {
    ...mapState({
      toSign: (state) => state.questionnaire.toSign,
      toComplete: (state) => state.questionnaire.toComplete,
      triad: (state) => state.triad.triad,
      user: (state) => state.user.user,
      questionnaires: (state) => state.questionnaire.questionnaires,
      questionnaireConfig: (state) => state.platform.questionnaireConfig,
      apprenticeProfileLabel: (state) => state.platform.apprenticeProfileLabel,
      tutorProfileLabel: (state) => state.platform.tutorProfileLabel,
      studeaManagerProfileLabel: (state) =>
        state.platform.studeaManagerProfileLabel,
      apprenticeMasterProfileLabel: (state) =>
        state.platform.apprenticeMasterProfileLabel,
    }),
  },
  mounted() {
    window.addEventListener("resize", this.setIconSize);
    if (this.accordion) {
      this.isCollapseOpened();
    }
  },
  methods: {
    fistLetterToUppercase(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
    },
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },
    info(questionnaire) {
      let ret = "";
      let breakline = false;
      const userRoles = this.triad.userRoles;
      const displayAll =
        userRoles.includes(4) || this.user.roles.includes("ROLE_STUDEA_ADMIN");
      if (
        questionnaire.signedBy.includes(1) &&
        (displayAll || userRoles.includes(1)) &&
        questionnaire.profileStatus.apprentice
      ) {
        ret = ret.concat(
          "A : " +
            this.$t("to_be_completed_from") +
            this.formatDate(questionnaire.profileStatus.apprentice.startDate) +
            this.$t("to") +
            this.formatDate(
              questionnaire.completenessDate
                ? questionnaire.completenessDate
                : questionnaire.profileStatus.apprentice.endDate,
            ),
        );
        breakline = true;
      }
      if (
        questionnaire.signedBy.includes(2) &&
        (displayAll || userRoles.includes(2)) &&
        questionnaire.profileStatus.tutor
      ) {
        if (breakline) {
          ret = ret.concat("\n");
        }
        ret = ret.concat(
          "T : " +
            this.$t("to_be_completed_from") +
            this.formatDate(questionnaire.profileStatus.tutor.startDate) +
            this.$t("to") +
            this.formatDate(
              questionnaire.completenessDate
                ? questionnaire.completenessDate
                : questionnaire.profileStatus.tutor.endDate,
            ),
        );
        breakline = true;
      }
      if (
        questionnaire.signedBy.includes(3) &&
        (displayAll || userRoles.includes(3)) &&
        questionnaire.profileStatus.apprenticeMaster
      ) {
        if (breakline) {
          ret = ret.concat("\n");
        }
        ret = ret.concat(
          "M : " +
            this.$t("to_be_completed_from") +
            this.formatDate(
              questionnaire.profileStatus.apprenticeMaster.startDate,
            ) +
            this.$t("to") +
            this.formatDate(
              questionnaire.completenessDate
                ? questionnaire.completenessDate
                : questionnaire.profileStatus.apprenticeMaster.endDate,
            ),
        );
      }
      return ret;
    },

    isManagerQuestionnaire() {
      let response = false;
      // if (
      //   this.triad &&
      //   this.triad.questionnaires &&
      //   Object.keys(this.triad.questionnaires)
      // ) {
      //   Object.values(this.triad.questionnaires).forEach(function (category) {
      //     if (!response) {
      //       Object.values(category.questionnaires).forEach(
      //         function (questionnaire) {
      //           if (!response) {
      //             if (questionnaire.lea_manager_infos.has_to_sign) {
      //               response = true;
      //             }
      //           }
      //         },
      //       );
      //     }
      //   });
      // }
      return response;
    },
    dates(questionnaire) {
      const infos = questionnaire[this.user.type + "_infos"];
      const start_date = dateFormatter.format(infos.start_date);
      const end_date = dateFormatter.format(infos.end_date);

      return start_date + "-" + end_date;
    },
    setIconSize() {
      const size = window.innerWidth < 768 ? "12px" : "15px";
      const icons = document.getElementsByClassName("category__icon");
      for (let i = 0; i < icons.length; i++) {
        icons[i].setAttribute("width", size);
      }
    },

    canManage(status) {
      const expired = new Date().getTime() > new Date(status.endDate).getTime();
      return (
        ((status.icon === "lock" && expired) || status.icon === "check") &&
        ((this.user.roles && this.user.roles.includes("ROLE_STUDEA_ADMIN")) ||
          (this.trainingCourse && this.trainingCourse.isStudeaManager))
      );
    },

    manage(status, profile, id) {
      const expired = new Date().getTime() > new Date(status.endDate).getTime();
      if (status.icon === "check" || (status.icon === "lock" && expired)) {
        studeaManagerManager
          .unlockReply(profile, id, this.triad.id)
          .then(() => {
            if (status.icon === "lock") {
              status.icon = "lock-open";
            }
            if (status.icon === "check") {
              status.icon = "hourglass-start";
            }
            this.key++;
          });
      }
    },

    expand(e, year) {
      const accordion = e.currentTarget;
      if (this.accordion && !accordion.classList.contains("collapsed")) {
        this.scrollTo(year);
      }
    },

    scrollTo(year) {
      const block = document.getElementById("questionnaire-block-" + year);
      const offset = 150;
      setTimeout(function () {
        window.scrollTo({
          behavior: "smooth",
          top:
            block.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        });
      }, 200);
    },

    isCollapseOpened() {
      if (!this.toComplete.length || !this.toSign.length) {
        if (this.todayYear === this.year.year) {
          this.openCollapse = true;
        }
      } else {
        this.openCollapse = false;
      }
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
.widget {
  height: auto;

  &-content {
    @include up-to-md {
      padding: 0 10px 10px 10px;
    }
  }

  &-heading {
    @include up-to-md {
      padding: 20px 10px;
    }
  }
}

.category {
  @include up-to-md {
    font-size: 12px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    background: $purple;
    color: $white;
    border-radius: $radius;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
  }

  &__line {
    display: flex;
    color: $blue;

    @include dark-theme {
      color: $white;
    }
  }

  &__roles {
    display: flex;
    width: 25%;
    background: $white;
    justify-content: space-between;

    @include dark-theme {
      background: $m-color_10;
    }

    @include up-to-xl {
      width: 30%;
    }

    @include up-to-lg {
      width: 40%;
    }

    &.-heading {
      background: $blue;

      @include dark-theme {
        background: $blue;
      }
    }
  }

  &__role {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: none;
    border: none;

    svg {
      font-size: 16px;
    }

    .fa-check {
      color: $green;
    }
  }

  &__title {
    width: 80%;
    padding: 10px;
    font-weight: 300;
  }
}

a.dropdown-item {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .title {
    padding-right: 20px;
  }
}

.secondary-color {
  background-color: var(--secondary-color) !important;
}

.info {
  padding: 10px 0 10px 5px;
}
</style>
