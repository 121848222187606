<template>
  <router-view></router-view>
  <MobileMenuComponent />
</template>

<script>
import MobileMenuComponent from "@/components/MobileMenuComponent.vue";
import { mapState } from "vuex";
import { colorManager } from "@/services/platform/color-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { userManager } from "@/services/security/user-manager";

export default {
  name: "DashboardLayoutView",
  components: { MobileMenuComponent },
  computed: {
    ...mapState({
      triad: (state) => state.triad.triad,
      trainingCourse: (state) => state.trainingCourse.trainingCourse,
      trainingCourseList: (state) => state.trainingCourse.trainingCourseList,
      questionnaireConfig: (state) => state.platform.questionnaireConfig,
      design: (state) => state.platform.design,
      needUpdate: (state) => state.platform.needUpdate,
      user: (state) => state.user.user,
      userProfile: (state) => state.user.userProfile,
    }),
  },
  watch: {
    userProfile() {
      this.$store.commit(
        "SET_USER_PROFILE_NAME",
        userManager.getProfileKey(this.userProfile),
      );
    },
    "trainingCourse.triads"() {
      if (
        !window.localStorage.getItem("studea-oldTriad") &&
        this.trainingCourse.triads &&
        Object.keys(this.trainingCourse.triads).length &&
        !this.isTrainingCourseList()
      ) {
        window.localStorage.setItem(
          "studea-oldTriad",
          this.trainingCourse.triads[0].id,
        );
        if (!Object.keys(this.triad).length) {
          this.loadTriad();
        }
      }
      if (
        this.trainingCourse &&
        this.trainingCourse.triads &&
        Object.keys(this.trainingCourse.triads).length === 1 &&
        this.trainingCourseList &&
        Object.keys(this.trainingCourseList).length === 1
      ) {
        window.localStorage.setItem(
          "studea-oldTriad",
          this.trainingCourse.triads[0].id,
        );
        if (this.$route.name === "TrainingCourseList") {
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
    $route() {
      if (this.isModulePageNeedTriadReload()) {
        this.loadTriad();
      }
    },
  },
  mounted() {
    this.init();
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  updated() {
    this.setCfaDesign();
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  methods: {
    init() {
      if (
        this.trainingCourse &&
        this.trainingCourse.id &&
        this.isModulePageNeedTriadReload()
      ) {
        this.loadTriad();
      }
      if (
        !Object.keys(this.trainingCourse).length &&
        !this.isTrainingCourseList()
      ) {
        this.$store.commit("SET_APP_LOADING", true);
        this.$store.dispatch("fetchTrainingCourseList", {}).then(() => {
          this.setCfaDesign();
          if (!Object.keys(this.triad).length) {
            this.loadTriad();
          }
        });
      } else {
        this.setCfaDesign();
      }
    },

    loadTriad() {
      const triadId = window.localStorage.getItem("studea-oldTriad")
        ? window.localStorage.getItem("studea-oldTriad")
        : null;
      if (triadId) {
        this.$store
          .dispatch("changeTriad", {
            triadId: triadId,
            trainingCourseId: this.trainingCourse.id,
            disableLoading: true,
          })
          .then(() => {
            this.checkUserProfile();
          });
      } else if (
        this.$route.matched.find((item) => item.path === "/dashboard")
      ) {
        this.$router.push({ name: "TrainingCourseList" });
      }
    },

    isModulePageNeedTriadReload() {
      const modulePagesNeedReloadTriad = [
        "MODULE_STUDEA_SKILL",
        "MODULE_STUDEA_NOTEPAD",
        "MODULE_STUDEA_COURSE",
        "MODULE_STUDEA_QUESTIONNAIRE",
        "MODULE_STUDEA_WEBLINKS",
        "MODULE_STUDEA_NOTE",
        "MODULE_STUDEA_DOCUMENT",
        "Dashboard",
      ];
      return this.$route.matched.find((item) =>
        modulePagesNeedReloadTriad.includes(item.name),
      );
    },

    isTrainingCourseList() {
      return this.$route.path.includes("training-course-list");
    },

    setColor() {
      if (this.$route.path === "/dashboard/training-course-list") {
        colorManager.setPlatformStyle(this.design);
      } else if (Object.keys(this.trainingCourse).length) {
        colorManager.setPlatformStyle(null, this.trainingCourse);
      }
    },

    setLogoCfa() {
      if (this.$route.path === "/dashboard/training-course-list") {
        this.$store.commit("SET_LOGO_CFA_PROMOTION", null);
      } else if (Object.keys(this.trainingCourse).length) {
        trainingCourseManager.setNewCfaLogo(this.trainingCourse);
      }
    },

    setCfaDesign() {
      this.setColor();
      this.setLogoCfa();
    },

    getConfig() {
      this.$store.dispatch("fetchConfig");
      this.$store.commit("SET_NEED_UPDATE", false);
    },

    checkUserProfile() {
      this.$store.commit(
        "SET_USER_PROFILE",
        userManager.checkUserProfile(this.user, this.triad),
      );
    },
  },
};
</script>

<style scoped></style>
